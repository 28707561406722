// Breadcrumb

.section--breadcrumb {
  background: $body-color;
  color: white;

  a {
    text-decoration: none;
  }

  .breadcrumb {
    padding: 0;
  }
}

.breadcrumb {
  margin: 0;
  background: none;
  padding-left: 0;
  li {
    a {
      color: $brand-color-2;
    }
    &.active {
      color: inherit;
    }
  }
}
