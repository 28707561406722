.link-primary {
    text-decoration: underline;
    color: $brand-color-3;

    &:hover,
    &:focus {
        color: $brand-color-8;
    }

    &:focus-visible {
        outline: 2px solid $brand-color-8 !important;
    }
}
