// List

ul.menu {
  padding-left: 0;
  li {
    list-style-type: none;
  }
}

#main {
  ul.list-arrows,
  ul:not(.menu--social):not(.list-unstyled):not(.contact-info):not(.product-properties):not(.two-columns):not(.three-columns):not(.product-details):not(.pagination):not(.errors):not(.list-bulletpoints):not(.list-circles):not(.list-crossmarks):not(.list-checks) {
    padding-left: 0;

    &:not(:last-child){
      margin-bottom: 2rem;
    }

    li {
      list-style-type: none;
      padding-left: 32px;
      margin-bottom: 0.6em;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 14px;
        background-image: url('/src/images/icons/link-arrow.svg');
        background-repeat:no-repeat;
        background-size:100%;
        background-position: left center;
        position: absolute;
        left: 0;
        top: 0.5em;
        //top: 50%;
        //transform: translateY(-50%);
      }
      a {
        text-decoration: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.menu--social {
  li {
    display: inline-block;
    margin-right: 0.25em;
    margin-top: 0.25em;
    a {
      img {
        transition: transform 300ms ease;
      }
      &:hover, &:focus {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

ul.client-info, ul.contact {
  padding-left: 0;
  li {
    list-style-type: none;
  }
}

ul.client-info li:first-child {
  font-weight: bold;
}

ul.contact-info {
  padding-left: 0;
  li {
    list-style-type: none;
    margin-bottom: 0.5em;
    svg,
    i {
      margin: -.1em .2em 0 0;
      color: inherit;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  li:first-child {
    font-weight: bold;
  }

  a[href^="tel:"] {
    #main & {
      color:currentColor;
      text-decoration: none;
    }
  }

}

ul.product-properties {
  font-size: 1rem;
  margin-top: 1em;
  padding-left: 1.25rem;
}


ul.product-details {
  padding-left: 0;
  li {
    list-style-type: none;
  }
}

ul.two-columns {
  columns: 2;
}

ul.three-columns {
  columns: 3;
}

.list-checks,
.list-crossmarks,
ul.benefits {
  padding-left: 0;

  > li {
    padding-left: 48px;
    position: relative;
    list-style-type: none;
    margin-bottom: 0.5em;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background-color: $brand-color-3;
      background-image: url('images/icons/check.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.list-checks > li {
  padding-left: 32px;
  margin-bottom: 0;

  &::before {
    top: 0.15em;
    width: 20px;
    height: 20px;
    background-size: 14px;
  }
}

.list-unstyled {
  li.media {
    img {
      margin-top: 4px;
      width: auto;
    }
  }
}

.list-arrows > li {
  margin-bottom: 0 !important;

  &::before {
    top: 0.4em;
  }
}

.list-crossmarks > li {
  padding-left: 32px;
  margin-bottom: 0;

  &::before {
    top: 0.3em;
    width: 20px;
    height: 20px;
    background-image: url('images/icons/close-white.svg');
    background-size: 10px;
  }
}

.list-circles,
.list-bulletpoints {
  padding-left: 22px;

  > li {
    padding-left: 10px;
  }
}

.list-circles > li::marker{
  content: '◯';
}

.list-circles {
  list-style-type: circle;
}
