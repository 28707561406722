.press-resource {
    display: flex;
    flex-direction: column;
    padding: 0;
    background: none;
    border: 0;
    text-align: left;
    transition: all .2s ease;
    width: 100%;
    flex: 1 1 auto;

    &__image {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        background: $brand-color-10;
        color: $brand-color-11;
        padding: map-get($spacers, 3);
        flex: 1 1 auto;
    }

    &__title {
        color: $white;
        margin: 0 0 map-get($spacers, 1) 0;
        font-size: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            margin-left:map-get($spacers, 2);
            transform: translateY(0.25em);
        }
    }

    &__details {
        margin-top: auto;
    }

    &:hover,
    &:focus {
        box-shadow: 0px 5px 0px 0px $brand-color-2, 0px 0px 40px 0px rgba(#04172D, 0.2);
    }
}

.press-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    padding: map-get($spacers, 4);

    &[aria-hidden="true"] {
        display: none;
    }

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: hsla(0, 0%, 0%, 0.5);
    }

    &__card {
        margin: auto;
        background: $white;
        padding: map-get($spacers, 5);
        width: 30em;
        max-width: 100%;
        z-index: 3;
        position: relative;

        .card-body {
            padding: 0 !important;
        }

        .alert {
            margin-bottom: map-get($spacers, 3);

            &:empty {
                display: none;
            }
        }

        form {
            width: 100%;

            button {
                width: 100%;
                margin-top: map-get($spacers, 4);
            }
        }
    }

    &__close {
        position: absolute;
        top: map-get($spacers, 2);
        right: map-get($spacers, 2);
        z-index: 3;
        padding: map-get($spacers, 2);
        background: none;
        border: 0;
        line-height: 0;
        border-radius: 100%;

        &:hover,
        &:focus {
            background: transparentize($brand-color-11, 0.75);
        }
    }
}

