.map__filter {


  .link-toggle {
    display:inline-flex;
    align-items: center;
    color:$brand-color-3;
    text-decoration: underline !important;

    .hide-map {
      display: none;
    }

    body.map-is--active & {

      .hide-map {
        display:inline-block;
      }
      .show-map {
        display:none;
      }

    }

  }

}

.map__results {
  @include font-size(18px);
}

.map__overview {

  .results {
    margin:0;
    padding:0 20px 20px 20px;
    @include font-size(20px);
    line-height:1;
    color: $brand-color-1;
    font-weight: $font-weight-bolder;
  }

  .map-container {
    width:100%;
    height:100vh;
    height: calc(100vh - var(--headerh, 159px) - 40px);
    max-height:900px;
    position: relative;

    .map {
      position: absolute;
      height:100%;
      width:100%;
    }

  }

  .map__overlay {
    position:sticky;
    top: 120px;
  }

  .map__list {

    position:relative;

    &.is-loading {

      &:after {
        content:"";
        height:100%;
        width:100%;
        background:rgba(#fff, 0.5);
        top:0;
        left:0;
        position:absolute;
      }

    }
  }

  @include media-breakpoint-down(md){

    .map__list {
      body.map-is--active & {
        margin-top:1rem;
      }
    }

    .map-container {
      height:100vw;
    }

    .results {
      padding:20px 8px;
      @include font-size(18px);
    }

    .map__overlay {
      height:0;
      overflow:hidden;

      body.map-is--active & {
        height:auto;
      }

    }

  }

}

