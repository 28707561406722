// Main menu

.menu-container-column {
  position: static;
}

#menu-container {
  @include media-breakpoint-down(md) {
    display:none;
    body.show-mobile-menu & {
      display:block;
    }
  }
}

ul.main-menu li {
  p.submenu-title {
    margin: 0;
    padding: 1rem;
    font-size: 1.25em;
    //border-bottom: 1px solid #d0d7e4;
  }
  .card--contact-shortcut {
    height: auto !important;
    box-shadow: none !important;
    background-color: #F1F5FE;
    border: none;
    margin: 0 1em 1em 1em;
    padding: 0;
    img.card-img-top {
      width: 72px;
      height: 72px;
      object-fit: contain;
      margin-bottom: 1em;
    }
    img {
      width: 72px;
      height: 72px;
      object-fit: contain;
      margin-bottom: 1em;
    }
    .card-body {
      .card-title {
        line-height: 1.33333;
        color: $brand-color-1;
      }
    }
    .card-footer {
      background: none;
      border-top: 0;
      padding-top: 0;
      .btn-link {
        color: $body-color;
        text-align: left;
        padding: 0 0 0 32px;
        font-size: 18px;
        position: relative;
        i {
          margin-right: 0.5em;
          color: $link-color;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 21px;
          height: 18px;
          background-image: url('/src/images/icons/link-arrow.svg');
          position: absolute;
          left: 0;
          top: 13px;
        }
      }
    }
    &:hover, &:focus {
      text-decoration: none;
      box-shadow: inset 0px -5px 0px 0px $brand-color-2, 0px 0px 40px 0px rgba(#04172D, 0.2);
      .btn-link {
        color: $link-color;
      }
    }
  }
}

.menu-controls {
  position: absolute;
  @include stick-to-edges();
  background: #FFF;
  border-bottom: 1px solid #d0d7e4;
  display: none;
  overflow: hidden;

  button,
  a {
    width: 60px;
    height: 60px;
    display: inline-block;
    transition: background-color 300ms ease;

    &:hover, &:focus {
      background-color: #dbe2ea;
    }
  }
}

.mobile-footer-menu {
  display:none;
}

@include media-breakpoint-down(md) {
  body.show-mobile-menu {
    overflow: hidden;
    &:after {
      content: '';
      display: block;
      @include stick-to-edges();
      min-height: 100%;
      bottom: -100px;
      position: fixed;
      background: $brand-color-1;
    }
    .menu-controls {
      display: flex;
    }
    ul.main-menu {
      display: block;
      background:#fff;
    }
    .mobile-footer-menu {
      &.is-active {
        display:block;
      }
    }
  }
  #menu-container {
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    background: $brand-color-1;
  }
  ul.main-menu {
    display: none;
    padding: 0;
    margin: 0;
    li {
      list-style-type: none;
      border-bottom: 1px solid #d0d7e4;
      background:#fff;

      p.submenu-title {
        background:#fff;
        a {
          color: currentColor;
          text-decoration: none;
        }
      }

      a:not(.parent),
      .toggle-subnav {
        padding: 1em;
        line-height: 1.8;
        display: block;
        position: relative;
        text-decoration: none;
        transition: background-color 300ms ease;
        cursor: pointer;
        color: $link-color;
        width: 100%;
        text-align: left;
        &:hover, &:focus {
          background-color: #dbe2ea;
        }
        i {
          display: none;
        }
      }
      > .toggle-subnav + a {
        display: none;
      }
      &.has-children {
        > .toggle-subnav {
          &:after {
            content: '';
            display: block;
            //background-color: green;
            background-image: url('/src/images/icons/arrow-right.svg');
            background-position: center center;
            background-repeat: no-repeat;
            width: 60px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }
      }
      &.parent {
        border-bottom: none;
        a, .toggle-subnav {
          font-weight: bold;
          color: #FFFFFF;
          background-color: $brand-color-3;
        }
      }

      .card--contact-shortcut {
        margin-left:0;
        margin-right:0;
        margin-bottom:0;
      }

    }
    .submenu {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      background-color: #ffff;
      min-height: 100%;
      z-index: 1;
      .row [class*="col"] {
        position: static;
      }
      ul {
        margin: 0;
        padding: 0;
        display: none;
        li.has-children {
          p.submenu-title {
            display: none;
            position: absolute;
            background-color: #FFF;
            left: 0;
            top: 0;
            right: 0;
            &.show {
              display: block;
            }
          }
          ul {
            display: none;
            position: absolute;
            left: 0;
            top: 63px;
            right: 0;
            background: #FFF;
            min-height: 100%;
            z-index: 1;
            &.show {
              display: block;
            }
          }
        }
      }
      &.show {
        display: block;
        ul {
          display: block;
        }
      }
    }
  }

}

@include media-breakpoint-up(lg) {
  #menu-container {
    display: block;
    position: static;
    background: none;
    height:auto !important;
  }
  ul.main-menu {
    margin: 0;
    padding: 0;
    display: flex;
    text-align: right;
    justify-content: flex-end;

    > li {
      display: inline-block;

      > a, > .toggle-subnav {
        height: 100%;
        padding: 2.063em 0.75em;

        @include media-breakpoint-up(xl) {
          padding: 1.75em 0.75em;
        }
        color: #456FA0;
        position: relative;
        display: block;
        cursor: pointer;

        &:hover, &:focus {
          text-decoration: underline;
        }

        &:focus-visible {
          outline: 2px solid $brand-color-3;
          background: lighten($brand-color-11, 15%);
          text-decoration: none;
        }

        &.active {
          &:after {
            content: '';
            display: block;
            height: 4px;
            background-color: $body-color;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }

      }
      .submenu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        text-align: left;
        border-top: 1px solid #d1dae4;
        pointer-events: none;
        .wrapper {
          background-color: white;
          padding: 0;
          pointer-events: auto;
          box-shadow: 0px 16px 12px 0px rgba(0,0,0,0.25);
          .row:first-child {
            .col-lg-4:last-child {
              background: #f1f5ff;
              ul {
                display: none;
              }
              .card--contact-shortcut {
                margin: 0;
                padding: 1em 3em 1em 1em;
                border: none;
              }
            }
          }
          .row:last-child {
            .col-12:not(.col-lg-4) {
              a.parent {
                padding: 1em 30px;
                margin-top: 0;
                display: block;
                border-top: 1px solid $border-color;
                color: $link-color;
                font-weight: bold;
                text-decoration: underline;
              }
            }
          }
        }
        ul {
          margin: 2em 1em 2em 2em;
          padding: 0;
          display: block;
          li {
            list-style-type: none;
            padding-left: 32px;
            margin-bottom: 0.6em;
            position: relative;
            &:before {
              content: '';
              display: inline-block;
              width: 21px;
              height: 18px;
              background-image: url('/src/images/icons/link-arrow.svg');
              position: absolute;
              left: 0;
              top: 6px
            }
            a {
              padding: 0;
              color: $body-color;
              i {
                display: inline-block;
                margin-right: 0.5em;
                color: $link-color;
              }
            }
          }
          li.parent {
            padding-left: 0;
            background: none;
            &:before {
              content: none;
            }
            a {
              font-weight: bold;
            }
          }
        }
        &.show {
          display: block;

          .is-scrollup & {
            display: none;
          }

        }
      }
      &.has-children {
        > .toggle-subnav {
          padding-right: 15px;

          &:before {
            content: '';
            display: block;
            background-image: url('/src/images/icons/arrow-down.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-color: transparent;
            width: 10px;
            height: 6px;
            position: absolute;
            right: 0;
            left: auto;
            top: 52.5%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  ul.main-menu {
    font-size: 18px;
    > li {
      padding: 0 0.25em;

      > a, > .toggle-subnav {
        padding: 1.78em 0.25em;
      }
    }
  }
}
