.bio-link {
    position: relative;
    display: flex;
    flex-direction: column;

    &__title {
        position: absolute;
        left: map-get($spacers, 4);
        right: map-get($spacers, 4);
        bottom: map-get($spacers, 4);
        background: $white;
        color: $brand-color-6;
        display: flex;
        text-align: center;
        padding: map-get($spacers, 2) map-get($spacers, 3);
        margin: 0;
        justify-content: center;
    }

    &__image {
        width: 100%;
        height: auto;
    }
}
