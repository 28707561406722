// Layout

.container-fluid {
  padding: 0 16px;
}

.container {
  @include media-breakpoint-down(sm) {
    padding: 0 16px;
  }
}

.container--1 {
  max-width: 1200px;
}

#container {
  //overflow: hidden;
}

.col-md-4.sidebar {
  @include media-breakpoint-up(md) {
    padding-left: 40px;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.border-bottom  { border-bottom: $border-width solid $brand-border-color !important; }

