// Typography

//* {
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}

body {
  font-family: $font-main;
}

.lh-1 {
  line-height: 1;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-header;
  font-weight: bold;
}

h1, .h1 {
  @include media-breakpoint-down(md){
      font-size:1.8rem;
  }
}

h2,.h2 {
  font-size: 1.8em;
  margin-bottom: 1rem;

  @include media-breakpoint-down(md){
    font-size:1.3333333333em;
  }

  + ul, ol,
  + table, .table {
    margin-top: 1.5rem;
  }

}


h3,.h3 {
  //font-size: 1.35em;
  font-size: 1.3333333333em;

  + ul, ol,
  + table, .table {
    margin-top: 1rem;
  }

}

.vacancy-card-title,
.faq-category-title {
  font-size: 1.5em;
  a {
    color: currentColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.specs-table {
  font-size:1rem;
}

hr {
  height: 1px;
  border: none;
  background-color: $brand-border-color;
  margin: 1em 0;
}

p.price {
  font-size: 32px;
  margin: 1rem 0 1em 0;
  color: $body-color;
  line-height: 1;
  span {
    font-size: 16px;
    display: block;
    margin-bottom: 4px;
    color: #8597ad;
  }
  span.ex-btw {
    display: inline-block;
    font-size: 11px;
    margin-left: 0.5em;
  }
}

.section-title {
  color:$headings-color;
  font-weight:600;
  margin-bottom:25px;
}

.section-header {
  margin-bottom:35px;
  .section-title {
    margin-bottom:0;
  }
}

.media-right-text-left h2, .media-left-text-right h2 {
  font-size: 1.5em;
  margin-bottom: 1.5rem;
  @include media-breakpoint-up(lg) {
    font-size: 2em;
  }
}

p.date {
  color: #456FA0;
}

p.intro {
  color: #456FA0;
  font-size: 20px;
}

blockquote {
  display: block;
  padding: 24px 0;
  border-top: 1px solid #8BACD2;
  border-bottom: 1px solid #8BACD2;
  p {
    font-family: $font-serif;
    font-size: 24px;
    font-style: italic;
    color: #456FA0;
  }
  figcaption {
    font-weight: bold;
    font-size: 1.125em;
  }
}

.sidebar {
  h2 {
    font-size: 1.5em;
    margin-bottom: 1em;
  }
}

p.frequency {
  font-size: 12px;
  span.value {
    font-weight: bold;
  }
}

p {
  + h2,
  + .h2,
  + h3,
  + .h3,
  + ul {
    margin-top: 2rem;
  }
}

.section--intro,
#main {
  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}


.section--product {
  .section-title {
    font-weight:bold;
    span {
      color: #3975bc;
    }
  }
}

.card-title {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  //-ms-hyphens: auto;
  //-moz-hyphens: auto;
  //-webkit-hyphens: auto;
  //hyphens: auto;
}
