#notifications {
  .notification {
    background-color: #FF8787;
    position: relative;
    font-size: 0.875em;
    max-height:0;
    transition: max-height 500ms ease;
    overflow:hidden;

    &.is-active {
      max-height:100px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1em;
    }
    p {
      margin: 0;
      padding: 1em 0;
      padding-right: 72px;
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
    a.close-notification {
      position: absolute;
      right: 1em;
      top: 1em;
      color: inherit;
      cursor: pointer;
      svg,
      i {
        margin-left: 0.25em;
      }
    }
    & + .notification {
      background-color: darken(#FF8787, 5%);
      & + .notification {
        background-color: darken(#FF8787, 10%);
      }
    }
  }
}
