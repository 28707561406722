// Featured

.featured {
  position: relative;
  @include media-breakpoint-up(md) {
    background-color: white;
  }
  @include media-breakpoint-up(lg) {
    background: none;
  }
  .media {
    position: relative;
    img {
      position: relative;
    }

    @include media-breakpoint-down(xs) {
      margin-right: -1em;
      &:before {
        content: '';
        display: block;
        @include stick-to-edges();
        top: 50%;
        background-color: white;
        left: -$grid-gutter-width;
        z-index: -1;
      }
    }

    .homepage & {
      @include media-breakpoint-down(sm) {
        margin:0 !important;

        &:before {
          right: -$grid-gutter-width;
        }

      }
    }



    @include media-breakpoint-up(lg) {
      height: 450px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:after {
        content: '';
        display: block;
        @include stick-to-edges;
        background: linear-gradient(to right, #192D45 0%, #192D45 10%, rgba(30, 49, 63, 0) 50%);
      }
    }
  }
  .caption {
    color: $body-color;
    background-color: white;
    padding: 0 1em 1em 1em;
    overflow: hidden;
    @include media-breakpoint-down(xs) {
      margin: 0 (-$grid-gutter-width);
    }
    @include media-breakpoint-up(sm) {
      padding: 1rem 2rem 2rem 2rem;
    }
    @include media-breakpoint-up(md) {
      margin: 0;
      padding: 2rem;
      background: none;
      position: relative;
    }
    @include media-breakpoint-up(lg) {
      margin: -450px 0 0 50px;
      max-width: 538px;
      padding: 0;
    }
    .title {
      font-size: 2.125em;
      font-weight: 600;
      margin: 1rem 0;
      font-family: $font-main;
      @include media-breakpoint-up(md) {
        margin: 0 0 2rem 0;
      }
      @include media-breakpoint-up(lg) {
        font-size: 50px;
        line-height: em(57,50);
        margin: 148px 0 30px 0;
        color: white;
        max-width: 90%;
      }
    }
    .text {
      @include media-breakpoint-up(lg) {
        padding: 2em;
        background-color: white;
        min-height: 320px;
      }
    }
    ul.contact-info {
      margin-bottom: 0;
    }
  }
}
