.asw-item {
  position:relative;

  h3 {
    a {
      color: currentColor;
    }
  }

  &:after {
    content:"";
    border-bottom:1px solid $brand-border-color;
    position:absolute;
    bottom:0;
    left:8px;
    right:8px;
  }
}

.asw-icon {
  display: inline-flex;
  flex-direction: column;
  height: 42px;

  svg {
    margin-top: auto;
    margin-bottom: 2px;
  }
}


#asw-autocomplete-container {

  position:relative;

  .input-group  {

    position:relative;

    input {
      padding-right:70px;
    }

    button {
      pointer-events: none;
      position: absolute;
      right:0;
      top:50%;
      transform: translateY(-50%);
    }

  }


  .results-autocomplete {

    position: absolute;
    top:100%;
    z-index:100;
    width:100%;

    background:#fff;
    padding:20px 30px;

    display:none;

    &:before {
      content:"";
      border-top:1px solid $brand-border-color;
      position:absolute;
      top:0;
      left:0;
      right:0;
    }

    p,
    ul {
      margin:0;
    }

    ul {
      li {
        a {
          display:inline-block !important;
        }
      }
    }


  }


  // visible
  &.is-visible {

    .input-group {
      border-bottom-right-radius: 0;
    }

    .results-autocomplete {
      display: block;
    }
  }

}
