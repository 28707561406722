.list-group--locations--map {
  .location-item {
    text-align: left;
    border-bottom: $brand-border-color 1px solid;

    &.is-active {
      background: $white;
      border-bottom: 0;
    }
  }

  .location-item__title {
    margin: 0;

    button {
      font-size: 24px;
      padding: 1rem 2.5rem 1rem 1rem;
      margin: 0;
      cursor: pointer;
      width: 100%;
      text-align: left;
      position: relative;

      &::after {
        content: '';
        display: block;
        background-image: url('/src/images/icons/arrow-down.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-color: transparent;
        width: 10px;
        height: 6px;
        position: absolute;
        right: 1em;
        top: 52.5%;
        transform: translateY(-50%);
      }

      &:focus-visible {
        outline: 2px solid $brand-color-3;
      }

      &[aria-expanded="true"] {
        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  .location-item__subtitle {
    font-size: 16px;
    font-weight: normal;
    color: $black;
    margin-top: 1rem;
    display: block;

    p {
      margin: 0;
    }
  }

  .location-item__description {
    font-size: 16px;
    padding: 0.75rem 1rem;

    &[aria-hidden="true"] {
      display: none;
    }

    > * {
      --spacing-top: 1rem;

      & + * {
        margin-top: var(--spacing-top);
      }
    }

    p {
      --spacing-top: 0;
    }
  }
}
