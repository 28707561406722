// Webring

#webring {
  background-color: $brand-color-1;
  color: white;
  position: relative;
  font-size: 14px;
  z-index: 10;
  // overflow-x: hidden;

  a {
    text-decoration: none;
  }

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
  .container {
    @include media-breakpoint-down(md) {
      max-width: none;
      padding: 0;
    }
  }
  .menu {
    margin: 0;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      float: left;
    }
    li {
      float: left;
      a {
        display: block;
        padding: 1em 1em;
        color: #8BACD2;
        font-weight: 300;
        line-height: 1;
        position: relative;

        @include media-breakpoint-down(xs) {
          padding: 1em 0.5em;
        }

        @include media-breakpoint-up(lg) {
          padding-left: 0;
          padding-right: 0;
          margin-right: 2em;
        }
        &.active {
          color: white;
          font-weight: 500;
          &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #EBEFF3;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -3px;
            @include media-breakpoint-up(lg) {
              border-bottom: 6px solid white;
            }
          }
        }
      }
      &:first-child {
        @include media-breakpoint-down(md) {
          a.active {
            &:after {
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 6px solid #FFFFFF;
            }
          }
        }
      }
    }
  }
  .menu--service {
    font-size: 14px;
    li {
      a {
        color: white;
        i {
          margin-right: 0.5em;
          vertical-align: top;
        }
        &:hover, &:focus {
          color: $brand-color-2;
        }
      }
    }
  }
  .dropdown.dropdown--my-rd4 {
    background-color: $brand-color-3;
    margin-left: 1em;
    &:before {
      content: '';
      display: block;
      background-color: inherit;
      @include stick-to-edges();
      left: -8px;
      right: auto;
      padding-right: 100vw;
      margin-right: 100vw;
      transform: translateX(-8px) skewX(-16deg);
      transition: all 300ms ease;
    }
    .btn {
      background: none;
      font-size: inherit;
      color: white;
      border: none;
      line-height: 1;
      padding: 1em 1em 1em 8px;
      position: relative;
      span.icon {
        img {
          height: 1em;
          width: auto;
          vertical-align: top;
        }
      }
      @include media-breakpoint-down(sm) {
        &:after {
          content: none;
        }
      }
      @include media-breakpoint-up(sm) {
        span.icon {
          margin-right: 0.5em;
        }
      }
      @include media-breakpoint-up(xl) {
        padding-right: 0;
      }
    }
    .dropdown-menu {
      padding: 1em;
      border: none;
      border-radius: 0;
      box-shadow: 0px 0px 40px 0px rgba(#04172D, 0.2);
      a {
        padding: 8px 16px;
        color: $body-color;
        i {
          color: $link-color;
          margin-right: 0.5em;
        }
      }
    }
  }

  .town-selector {
      font-size:14px;

      .custom-select {
        background-color:$brand-color-5;
        color: $white;
        font-size: inherit;
        box-shadow: none !important;
        border:none;
        height:33px;
        min-width:180px;
        border-radius: 2em;
        padding-top:0;
        padding-bottom:0;

        background-image: url('/src/images/icons/custom-select-light.svg');
        background-size: 10px;

        &:focus {
          outline: 2px solid $brand-color-4;
        }
      }
  }

}

