#CybotCookiebotDialog {
  z-index: 1000 !important;
}

#cookiebanner {
  .cb-btn {
    &:focus {
      outline: 3px solid transparentize($brand-color-3, 0.5) !important;
    }
  }

  .cb-accordion-title:focus-visible {
    outline: 3px solid transparentize($brand-color-3, 0.5) !important;
  }

  .switch-toggle-container .switch-toggle {
    transition: none !important;
  }

  .switch-toggle-container input:focus:checked + .switch-toggle {
    outline: 3px solid transparentize($brand-color-3, 0.5) !important;
  }
}
