// Salestool

.salestool-form {
  background: $brand-color-3;
  color: white;
  h2 {
    color: inherit;
  }
  select, input, button {
    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }
  button {
    line-height: 1;
    @include media-breakpoint-only(md) {
      width: 100%;
    }
  }
}


#placement-address {
  display: none;
}

