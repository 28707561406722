[data-toggle="collapse"] {

  .collapse-less {
    display:none;
  }

  .collapse-more {
    display:inline-block;
  }

  &[aria-expanded="true"] {
    .collapse-more {
      display:none;
    }
    .collapse-less {
      display:inline-block;
    }
  }


}
