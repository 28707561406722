.section--dynamic_counters {
  background: #EAE31F;
  color: #1B3866;
  .card {
    background-color: #E0D228;
    div.statistic {
      p.value {
        display: block;
        font-weight: bold;
        color: white;
        font-size: 2.5em;
        margin: 0 0 0.5rem 0;
        line-height: 1;
      }
      p.description {
        font-size: 24px;
        margin-bottom: 1em;
        max-width: 360px;
        color: #1B3866;
        font-weight: bold;
      }
      &:last-child {
        p.description {
          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
          }
        }
      }
    }
    .img-container {
      @include media-breakpoint-up(lg) {
        padding: 1em 0;
      }
      text-align: left;
      img {
        max-width: 80px;
        max-height: 50px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left;
        @include media-breakpoint-up(lg) {
          max-width: 100px;
          max-height: 80px;
          object-position: center;
        }
      }
    }
  }
}

@keyframes zoom {
  0% { transform: scale(1); }
  50% { transform: scale(2); }
  100% { transform: scale(1); }
}
