// Card

.card {
  border: none;
  transition: all .2s;
  text-decoration: none;

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $brand-color-10;
  }

  .card-title {
    font-size: 1.333333333333333em;
    line-height: 1.33333;
    border-bottom: 1px solid #EBEFF3;
    padding: $card-spacer-x;
    margin: 0;

    &.card-title-lg {
      font-size: 2.125em;
      line-height: 1.2;
    }

    a {
      color: currentColor;
      text-decoration: none;
    }

    @include media-breakpoint-down(lg) {
      &.card-title-mobile-lg {
        font-size: 1.888888888888889em;

      }
    }

    @include media-breakpoint-up(lg) {
      &.card-title-lg {
        font-size: 50px;
        line-height: em(57,50);
        max-width:90%;
      }
    }

  }

  .card-body {
    .card-title {
      padding: 0;
      margin-bottom: 0.7em;
      border: none;

      @include media-breakpoint-up(lg) {
        &.card-title-lg {
          margin-bottom: 40px;
        }
      }

    }

    & + .card-body {
      border-top: 1px solid $card-border-color;
    }
    &.p-5 {
      .card-title {
        margin-bottom: 1em;
      }
    }

    blockquote {
      &:last-child {
        margin-bottom:0;
      }
    }

  }
  .card-footer {
    background-color: white;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  table, .table {
    margin-bottom: 1em;
  }
  .btn {
    svg,
    i {
      margin-right: 0.5em;
      color: white;
    }
  }
  .btn-link {
    color: $body-color;
  }

  p + .btn-link {
    margin-top: 1rem;
  }

  // widget
  &.card--widget {
    .card-body {
      h3, .h3 {
        @extend .card-title;
      }

      .btn {
        margin-top: 0.2rem;
      }

    }
  }

  .form.has-max-width {
    .form-group:not(.Checkbox ) {
      max-width: 400px;
    }
  }


}


.card--clean {
  background: none;
  .card-body {
    padding:0;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $headings-color;
  }

}

.card--dark {
  background: $brand-color-11;
}

.section--article {
  .card:not(.card--clean) {
    margin: 32px 0;
  }
}

[class*="col"]:not(.col-lg-8, .col-sm-10.col-md-8.col-lg-6) .card:not(.card--checkout-product) {
  height: 100%;
}

.card--fullwidth {

  position: relative;

  .card-body {
    padding:0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50vw;
    //transform: translateX( -50%);
    height: 100%;
    width: 100vw;
    background: #fff;


  }

  .media {

    @include media-breakpoint-down(sm) {
      position: relative;

      &:before {
        content: '';
        display: block;
        @include stick-to-edges();
        height: 50%;
        background-color: $body-bg;
        left: -$grid-gutter-width;
        right: -$grid-gutter-width;
        z-index: 0;
      }

      img {
        position: relative;
        z-index: 1;
      }
    }

  }


}

.card--summary {
  background: #fff;

  img {
    width:100%;
  }

}

.card--locations {
  border: none;
  height: 100%;
  min-height: 372px;

  .image {
    position: relative;
    overflow:hidden;
    img {
      width: 100%;
      height: auto;
    }
    &:after {
      content: '';
      position: absolute;
      top: -8px;
      right: -8px;
      bottom: -8px;
      left: -8px;
      transition: all .2s ease;
      border: 8px solid #fff;
    }
  }
  .card-title {
    margin-bottom: 1em;
    font-size: 1.33333em;
    color: $brand-color-1;
    line-height: 1.33333;
  }

  p {
    color: $body-color;
    &:last-child {
      margin-bottom:0;
    }
  }

  .card-footer {
    padding-top: 0;
    border-top: 0;
  }
  &:hover, &:focus {
    text-decoration: none;

    //.slick-slide & {
    box-shadow: 0px 5px 0px 0px $brand-color-2, 0px 0px 40px 0px rgba(#04172D, 0.2);
    //}

    .btn-link {
      color: $link-color;
    }
    .image {
      &:after {
        left:0;
        top:0;
        right:0;
        bottom:0;
      }
    }
  }
}

.card--news {
  border: none;
  height: 100%;
  min-height: 372px;
  @include media-breakpoint-down(sm) {
    width: 295px;
  }
  .image {
    position: relative;
    overflow:hidden;
    img {
      width: auto;
      height: auto;
    }
    &:after {
      content: '';
      position: absolute;
      top: -8px;
      right: -8px;
      bottom: -8px;
      left: -8px;
      transition: all .2s ease;
      border: 8px solid #fff;
    }
  }
  .card-title {
    margin-bottom: 1em;
    font-size: 1.33333em;
    color: $brand-color-1;
    line-height: 1.33333;
  }

  .card-footer {
    padding-top: 0;
    border-top: 0;
  }
  &:hover, &:focus {
    text-decoration: none;

    //.slick-slide & {
      box-shadow: 0px 5px 0px 0px $brand-color-2, 0px 0px 40px 0px rgba(#04172D, 0.2);
    //}

    .btn-link {
      color: $link-color;
    }
    .image {
      &:after {
        left:0;
        top:0;
        right:0;
        bottom:0;
      }
    }
  }
}

.card--tips {
  border: none;
  height: 100%;
  min-height: 372px;
  @include media-breakpoint-down(sm) {
    width: 295px;
  }
  .image {
    position: relative;
    overflow:hidden;
    img {
      width: auto;
      height: auto;
    }
    &:after {
      content: '';
      position: absolute;
      top: -8px;
      right: -8px;
      bottom: -8px;
      left: -8px;
      transition: all .2s ease;
      border: 8px solid #fff;
    }
  }

  .card-title {
    margin-bottom: 1em;
    font-size: 18px;
    color: $brand-color-1;
    line-height: 1.33333;
  }

  .card-footer {
    padding-top: 0;
    border-top: 0;
  }
  &:hover, &:focus {
    text-decoration: none;
    box-shadow: 0px 5px 0px 0px $brand-color-2, 0px 0px 40px 0px rgba(#04172D, 0.2);
    .btn-link {
      color: $link-color;
    }
    .image {
      &:after {
        left:0;
        top:0;
        right:0;
        bottom:0;
      }
    }
  }

}

.card--notification {
  border: none;
  height: 100%;
  min-height: 372px;

  @include media-breakpoint-down(sm) {
    width: 295px;
  }

  .card-title {
    margin-bottom: 1em;
    font-size: 18px;
    color: $brand-color-1;
    line-height: 1.33333;
  }

  .card-body {
    padding: calc(40px + 1.25rem) 1.25rem 1.25rem;
  }

  .card-footer {
    padding-top: 0;
    border-top: 0;
  }
  &:hover, &:focus {
    text-decoration: none;
    box-shadow: 0px 5px 0px 0px $brand-color-2, 0px 0px 40px 0px rgba(#04172D, 0.2);
    .btn-link {
      color: $link-color;
    }
    .image {
      &:after {
        left:0;
        top:0;
        right:0;
        bottom:0;
      }
    }
  }

  .label {
    background-color: darken(#FF8787, 5%) !important;
    //color: $brand-color-1 !important;
  }

}

.card--news, .card--tips, .card--notification {
  .label {
    font-size:18px;
    height:30px;
    line-height: 30px;
    padding:0 10px;
    background: $brand-color-1;
    color: #fff;
    text-transform: uppercase;
    font-weight:bold;
    position: absolute;
    left:8px;
    top:8px;
    z-index:5;
    display: inline-block;
  }

  .slick-slide & {
    &:hover, &:focus {
      box-shadow: none !important;
    }
  }

}

.card--toptask {
  border: none;
  height: 100%;
  img.card-img-top {
    width: 72px;
    height: 72px;
    object-fit: contain;
    margin-bottom: 1em;
  }
  img {
    width: 72px;
    height: 72px;
    object-fit: contain;
    margin-bottom: 1em;
  }
  .card-body {
    .card-title {
      line-height: 1.33333;
      color: $brand-color-1;
    }
  }
  .card-footer {
    background: none;
    border-top: 0;
    padding-top: 0;
    .btn-link {
      color: $body-color;
      text-align: left;
    }
  }
  &:hover, &:focus {
    text-decoration: none;
    box-shadow: inset 0px -5px 0px 0px $brand-color-2, 0px 0px 40px 0px rgba(#04172D, 0.2);
    .btn-link {
      color: $link-color;
    }
  }
}

.card--dashboard {
  img.card-img-top {
    width: 72px;
    height: 72px;
    object-fit: contain;
    margin-bottom: 1em;
  }
  img {
    width: 72px;
    height: 72px;
    object-fit: contain;
    margin-bottom: 1em;
  }
}

.card--other {
  border: none;
  height: 100%;
  .card-title {
    font-size: 1.888888888888889em;
  }
  ul {
    margin: 0;
  }
}

.card--waste {

  text-decoration: none;

  &:hover, &:focus {
    box-shadow: 0px 5px 0px 0px $brand-color-2, 0px 0px 40px 0px rgba(#04172D, 0.2);
    text-decoration: none;
  }

  .col-body {
    padding:1.25rem;
    min-height:140px;
  }

  .col-image {
    padding:0.625rem;
  }

  .card-title {
    @include font-size(20px);
  }

  .card-footer {
    .btn-link {
      color: $link-color;
      text-decoration: none;
    }
  }

  .ratio {
    position: relative;

    width: 100%;
    height:0;
    padding-bottom: calc(120 / 140 * 100%);
    overflow:hidden;

    img {
      position:absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top:0;
      left:0;
    }
  }

  .card-footer {
    border-top:0;
  }

  @include media-breakpoint-down(lg) {
    .col-body {
      padding-right:0;
    }
  }

}

.card--category {
  border: none;
  text-decoration: none !important;
  .card-title {
    color: inherit;
  }
  img {
    width: 72px;
    height: 72px;
    object-fit: contain;
    margin-bottom: 1em;
  }
  .card-body {
    background-color: $brand-color-1;
    background-size: cover;
    background-position: center;
    height: 200px;
    border-bottom: none;
    position: relative;
    color: white;
    &:before {
      content: '';
      @include stick-to-edges();
      background-color: $brand-color-1;
      opacity: 0.8;
      transition: opacity .2s ease;
    }
    * {
      position: relative;
    }
    .card-title {
      padding: 0;
      border: none;
    }
  }
  .card-footer {
    .btn-link {
      color: $body-color;
    }
  }
  &:hover, &:focus {
    box-shadow: 0px 5px 0px 0px $brand-color-2, 0px 0px 40px 0px rgba(#04172D, 0.2);
    .card-body {
      background-color: $brand-color-3;
      color: white;
      &:before {
        opacity: 0.1;
      }
    }
    .card-footer {
      .btn-link {
        color: $link-color;
      }
    }
  }
}

.card--product {
  border: none;
  text-decoration: none !important;
  .card-body {
    background-color: white;
    color: $body-color;
    height: auto;
    border-bottom: none;
    .card-title {
      padding: 0;
      border: none;
      span {
        color: $link-color;
      }
    }
    p:last-child {
      margin-bottom: 0;
      a {
        color: inherit;
        text-decoration: underline;
        &:hover, &:focus {
          color: $link-color;
        }
      }
    }
  }
  .product-image {
    background-color: white;
    height: 100%;
    img {
      margin-left: auto;
      margin-right: auto;
      width: auto;
      @include media-breakpoint-down(xs) {
        height: 160px;
      }
      height: 277px;
      object-fit: contain;
    }
    a {
      display: block;
    }
  }
  .card-footer {
    background-color: white;
    p.price {
      margin: 0;
      color: $headings-color;
    }
    .btn-primary {
      padding: 0.5em 1em;
    }
    .btn-link {
      color: $body-color;
    }
  }
  &:hover, &:focus {
    text-decoration: none;
    box-shadow: 0px 5px 0px 0px $brand-color-2, 0px 0px 40px 0px rgba(#04172D, 0.2);
    .btn-link {
      color: $link-color;
    }
  }
}

[class*="col"] .card--product {
  height: 100%;
}

.card--checkout-product {
  margin-bottom: .5em;
  .row, [class*="col"] {
    //border: 1px solid black;
  }
  .card-body, .card-footer {
    padding: 1em;
  }
  .card-body {
    .product-image {
      width: 120px;
      height: 120px;
      border: 1px solid #EBEFF3;
      padding: 4px;
      @include media-breakpoint-up(lg) {
        width: 145px;
        height: 145px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .card-title {
      span {
        color: $link-color;
      }
    }
    p.frequency {
      font-size: 1em;
      margin: 0;
    }
    p.price {
      margin: 0;
      font-size: 1.333333333333333em;
      line-height: 1.33333;
    }
    .btn-link {
      font-size: 16px;
      text-decoration: underline;
    }
    .btn-link.replace {
      color: #114DC9;
    }
    .btn-link.delete {
      color: #F60000;
    }
    //select.form-control, input[type="date"].form-control {
    //  font-size: 13px;
    //  height: 32px;
    //}
    select {
      background-size: 12px auto;
      color: $brand-color-1;
      border: 1px;
    }
    input[type="date"] {
      background-size: 12px auto;
      padding-right: 4px;
      color: $brand-color-1;
    }
  }
  .card-footer {
    border-top: 1px solid #EBEFF3;
    label {
      font-size: 16px;
      color: #04172D;
    }
    select {
      background-size: 12px auto;
      color: $brand-color-1;
      border: 1px;
    }
    input {
      border-color: #ebeff3;
    }
    input.datepicker {
      position: relative;
      &:after {
        content: '';
        width: 48px;
        height: 48px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: white;
        background-image: url('/src/images/cart/calender-icon.svg');
        background-size: 32px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    input[type="image"] {
      width: 50px;
      height: 50px;
      padding: 8px;
      opacity: 0.8;
      background-color: #0A366A;
      object-fit: contain;
      &:hover, &:focus {
        opacity: 1;
      }
    }
    .input-group-text {
      border-color: #ebeff3;
      border-right: none;
    }
  }
}

.card--receipt {
  padding: 0 $card-spacer-x;
  height: auto !important;
  .card-body, .card-footer {
    padding-left: 0;
    padding-right: 0;
  }
  .receipt {
    margin: 0;
    .item {
      background-color: #EBEFF3;
      padding: 1rem;
      margin-bottom: 2px;
      &:last-child {
        margin-bottom: 0;
      }
      h3 {
        font-size: 14px;
        margin: 0 0 0.5rem 0;
        span.price {
          float: right;
        }
      }
      p {
        font-size: 12px;
        margin: 0;
        span.value {
          font-weight: bold;
        }
        &.price-on-request {
          color: $brand-color-3;
        }
      }
    }
    hr {
      margin: 12px 0;
    }
    .form-group {
      margin: 0;
      font-size: 12px;
      .form-check-input {
        margin-top: .3em;
      }
      span {
        float: right;
      }
    }
    p.duration {
      span {
        float: right;
      }
    }
  }
  .card-footer {
    padding-top: 1em;
  }
  p.total {
    font-size: 20px;
    span {
      float: right;
      font-weight: bold;
    }
  }
}

.card--form {
  background: none;
  .card-title, .card-body {
    background-color: white;
  }
  .card-footer {
    background: none;
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.section--product-details {
  .card {
    @include media-breakpoint-up(md) {
      height: 100%;
    }
  }
}

.card-overview {
  @include media-breakpoint-down(lg) {
    background:#fff;

    p {
      &:last-child {
        margin-bottom:0;
      }
    }

  }
}
