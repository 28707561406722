// Article

.section--article {
  p + img {
    margin: 32px 0;
    display: block;
  }
  .col-sm-10.col-md-8.col-lg-6 > .card:first-child {
    .card-body > .row > .col-12 > p:first-child {
      strong {
        font-size: 1.1111111111111111em;
        font-weight: normal;
        color: #456FA0;
      }
    }
  }
}

#main > .section--article:first-child {
  padding-top: 32px;
}
