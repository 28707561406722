$pill-padding-y: 0.75rem;

.pill {
  display: inline-flex;
  background: $brand-color-4;
  //background: #fff;
  padding: 0.6rem 0.3rem;
  line-height: 1;
  border-radius: 2em;
  //color: $brand-color-3;
  color: $brand-color-6;
  text-decoration: none;
  align-items: center;
  border: 0;

  span {
    margin-top: -0.1em;
  }

  // Exclude svg from calculation the heigh of the pill element.
  svg {
    margin-top: calc(#{$pill-padding-y} * -1);
    margin-bottom: calc(#{$pill-padding-y} * -1);

    g {
      fill: currentColor;
    }

    + * {
      margin-left: map-get($spacers, 2);
    }
  }

  .pill-icon-floating {
    position: absolute;
    top: 50%;
    right: map-get($spacers, 3);
    transform: translateY(-50%);
  }

  &:focus,
  &:hover {
    background: darken($brand-color-4, 5%);
    background: $brand-color-4;

    color: $brand-color-3;
    text-decoration: none;
  }

  &:focus-visible {
    outline: 2px solid $brand-color-2 !important;
  }

  @include media-breakpoint-down(sm){
    height: 33px;
    width: 33px;

    border-radius: 0;
    justify-content: flex-end;

    .pill-text {
      @include sr-only;
    }

    &--is-mijnrd4 {
      background: transparent !important;
      padding: 0;
      color: $brand-color-9;

      &:hover {
        color: $white;
      }

    }

  }

}

.pill--primary {
  background: $brand-color-3;
  color: $white;

  &:focus,
  &:hover {
    background: lighten($brand-color-3, 10%);
    color: $white;
  }
}

.pill--brand-6 {
  background: $brand-color-6;
  color: $white;

  &:focus,
  &:hover {
    background: lighten($brand-color-6, 10%);
    color: $white;
  }
}
