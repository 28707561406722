// Swiper

.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: contain;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
}

.gallery-top {
  height: 416px;
  width: 100%;
}

.swiper-container.gallery-thumbs {
  height: 100px;
  box-sizing: border-box;
  padding: 10px 0 0;
}

.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}
