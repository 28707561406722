.selector-title {
  text-align: left;
  margin-bottom:10px;
  font-size: inherit;
}

#town-selector {

  .or {
    font-weight:bold;
    width:100%;
  }

  .alert-wrong-postalcode {
    font-size:16px;
    line-height: em(24, 14);
    color: #dc3545;
    margin: 1.5rem 0;
  }

  .helper {
    color: #5D6773;
    background: #F2F2F2;
    padding:15px;
    font-size:14px;
    line-height: em(24, 14);

    text-align: center;

    p {
      &:last-child {
        margin-bottom:0;
      }
    }

  }

  .custom-select {
    height:50px;
    width: 100%;
    padding-left:1em;
    border-radius:4px;
  }

  .btn {
    white-space: nowrap;
  }


  @include media-breakpoint-up(md){

    .or,
    .form-control,
    .custom-select {
      height:70px;
    }

    .or {
      line-height: 70px;
      width:70px;
      text-align: center;

      + .municipality-selector {
        width:calc(100% - 70px);
        .form-select {
          width:100%;
        }
      }
    }

  }

  @include media-breakpoint-up(lg){

    .custom-select {
      padding-left:1.25em;
    }

    .btn {
      width:100%;
      height:70px;
      padding-left:0;
      padding-right:0;
    }


  }

}

.helper-town-selector {
  color: #5D6773;
  background: $brand-color-11;
  padding:15px;
  font-size:14px;
  line-height: em(24, 14);
  text-align: center;

  p {
    &:last-child {
      margin-bottom:0;
    }
  }

}
