// Base

html {
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.raptorize {
  text-decoration: none !important;
  color: currentColor;
  cursor: default;
}

// Override Google translate bar offsetting
body {
  top: 0 !important;
}
