// Table

$table-head-color: $brand-color-11;
$table-row-color-light: $brand-color-4;
$table-row-color-card: #EBEFF3;

table {

  width: 100%;
  margin-bottom: $spacer;
  color: $table-color;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
  }

  thead th {
    color: $brand-color-10;
    background: $table-head-color;
    border:none;
    vertical-align: bottom;
  }

  a.download {
    &:after {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      background-image: url('/src/images/icons/download-factuur.svg');
      vertical-align: middle;
      margin-left: 0.5em;
      margin-top: -2px;
    }
  }

  .text-right {
    white-space: nowrap;
  }

  tbody {

    tr:nth-child(odd) {
      background: darken($table-row-color-light, 5%);
    }

    .card:not(.card--clean) & {
      tr:nth-child(odd) {
        background-color: #EBEFF3;
      }

      tr {
        td {
          border-bottom:0;
        }
      }

    }

    tr.month-divider th {
      background-color: #456FA0;
      color: white;
    }

    td {
      border: none;
    }

  }

  &:not(.price-rates){
    thead {

      + tbody {

        tr {
          &:nth-child(odd), &:nth-child(even) {
            background: transparent;
          }

          td {
            border-bottom:1px solid $table-head-color;
          }

          &:last-child {
            td {
              border-bottom-width: 0;
            }
          }

        }

        .card:not(.card--clean) & {
          tr:nth-child(odd) {
            background-color: transparent;
          }

          tr:nth-child(even) {
            background-color: $table-row-color-card;
          }

        }

      }

    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

}

.table-container {
  &:before {
    content: "De onderstaande tabel kun je horizontaal scrollen:";
    display: block;
    padding: 0.5em;
    margin-bottom: 1em;
    background-color: #f7efcf;
    border-left: 4px solid #CCCCCC;
    padding: 0.5em;
    border-color: #ffc335;
    font-size: 0.875em;
  }
}

table.table--scrollable {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  //@include breakpoint (medium) {
  //  width: 100%;
  //  display: table;
  //  white-space: normal;
  //  overflow-x: visible;
  //}
}
