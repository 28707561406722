// Forms

form {
  input:focus {
    border-color: orange;
  }
  .form-group label.form-label, label.col-form-label {
    font-weight: bold;
  }
  label {
    img.info-icon {
      margin-top: -3px;
      margin-left: 3px;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

}

select.custom-select {
  background-image: url('/src/images/icons/custom-select.svg');
  background-size: 20px 12px;
  padding-right: 50px;
  height: 45px;
  border-radius: $btn-border-radius;
}

.form-group.form-check {
  margin: 1.5em 0;
}

.card {
  select, .custom-select {
    background-color: #ffffff;
    border: $input-border-width solid $input-border-color;
  }
}

.col-md-8 {
  @include media-breakpoint-up(lg) {
    .form-group {
      margin-bottom: 1em;
    }
  }
}

@include media-breakpoint-up(lg) {
  form {
    .form-check-input {
      margin-top: 0.5em;
    }
  }
}

.form-group, .form-select {
  &.hide {
    display: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}

.validator-err {
  color: $danger;
  margin: 0.5em 0 0 0;
  font-size: $font-size-sm;
}

.custom-control {
  display: grid;
  grid-template-areas:
    "input label"
    "validation validation";
  grid-template-columns: auto 1fr;

  input {
    grid-area: input;
  }

  label {
    grid-area: label;
  }

  .validator-err {
    grid-area: validation;
  }
}

.wheelform {
  abbr {
    text-decoration: none;
  }
}
