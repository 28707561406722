.person-portrait {
    position: relative;
    max-width: 30em;
    margin: 0 auto;

    img {
        width: 100%;
    }

    .info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(4,23,45, .9);
        padding: 1em;
        margin-bottom: 0;
        color: $white;
    }
}
