// Button

.btn {
  font-weight:500;
  //@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  padding-top:$btn-padding-y - 0.1;
  padding-bottom: $btn-padding-y + 0.1;

  &.btn-lg {
    font-size:18px;
  }

  &:focus-visible {
    outline: 4px solid transparentize($brand-color-3, 0.5) !important;
  }

}

.btn-label {

  font-size:16px;
  padding: 0.4rem 1rem 0.6rem 1rem;
  border-radius: 22px;

  @include media-breakpoint-up(lg){
    &.btn-lg {
      border-radius: 36px;
      padding: 0.4rem 1.5rem 0.6rem 1.5rem;
      font-size:18px;
    }
  }


}

.btn-backlink {

  padding-left: 32px;
  position: relative;
  font-size:16px;
  line-height:1;
  //line-height:em(23,18);
  color: $brand-color-3;
  text-decoration: none;

  .section--back-link & {
    font-weight:600;
    color: $brand-color-1;
  }

  .section--hero & {
    color:#fff;
  }

  @include media-breakpoint-up(lg){
    font-size:18px;

    .section--back-link & {
      font-weight:normal;
    }

  }

  &:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 14px;
    background-image: url('/src/images/icons/arrow-left.svg');
    background-repeat:no-repeat;
    background-size:100%;
    background-position: left center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .section--hero & {
    color:#fff;

    &:before {
      background-image: url('/src/images/icons/arrow-left-white.svg');
    }

  }

}

.btn--download {
  padding-left: 64px;
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    width: 32px;
    height: 24px;
    background-image: url('/src/images/brochure/download-icon.svg');
    position: absolute;
    left: 18px;
    top: 11px;
  }
}

.btn-link {
  padding-left: 32px;
  position: relative;
  font-size:18px;
  line-height:em(23,18);
  font-weight:normal;
  display: inline-block;
  text-decoration: none;
  &:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 14px;
    background-image: url('/src/images/icons/link-arrow.svg');
    background-repeat:no-repeat;
    background-size:100%;
    background-position: left center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    text-decoration: none;
  }
}

.btn-rebooted {
  background: none;
  border: 0;
  padding: 0;
  line-height: 1;
  color: inherit;
  font-weight: inherit;
}
