// Slick

.slick-list {
  .slick-loading & {
    background-image: url("/src/images/slick/ajax-loader.gif") !important;
  }
}

.custom-slick-controls {
  color: #0a366a;
  a {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    transition: all 300ms ease;
    cursor: pointer;
    vertical-align: middle;
    margin-right: 8px;
    img {
      vertical-align: top;
    }
    &:hover, &:focus {
      background-color: white;
    }
  }
  span {
    vertical-align: middle;
  }
}

#employee-slider {
  @include media-breakpoint-up(md) {
    margin-left: 64px;
  }
  width: 840px;
  overflow: hidden;
  .slick-list {
    overflow: visible;
  }
  .card--employee {
    width: 320px;
    max-width: 320px;
    background: none;
    margin: 0 20px 0 0;
    vertical-align: top;
    position: relative;
    img {
      margin: 0;
    }
    p.name {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba($brand-color-1, 0.9);
      color: white;
      padding: 1em;
      margin: 0
    }
  }
}
