$sizes: 0, 32px, 64px, 94px;

.spacer {

  @each $size in $sizes {

    $i: index($sizes, $size);

    // paddings
    &-pt-#{$i - 1} {
      padding-top: $size !important;
    }

    &-pb-#{$i - 1} {
      padding-bottom: $size !important;
    }

    &-py-#{$i - 1} {
      padding-top: $size !important;
      padding-bottom: $size !important;
    }

    // margins
    &-mt-#{$i - 1} {
      margin-top: $size !important;
    }

    &-mb-#{$i - 1} {
      margin-bottom: $size !important;
    }

    &-my-#{$i - 1} {
      margin-top: $size !important;
      margin-bottom: $size !important;
    }

    @include media-breakpoint-up(md) {

      // paddings
      &-pt-md-#{$i - 1} {
        padding-top: $size !important;
      }

      &-pb-md-#{$i - 1} {
        padding-bottom: $size !important;
      }

      &-py-md-#{$i - 1} {
        padding-top: $size !important;
        padding-bottom: $size !important;
      }

      // margins
      &-mt-md-#{$i - 1} {
        margin-top: $size !important;
      }

      &-mb-md-#{$i - 1} {
        margin-bottom: $size !important;
      }

      &-my-md-#{$i - 1} {
        margin-top: $size !important;
        margin-bottom: $size !important;
      }
    }


    @include media-breakpoint-up(lg) {

      // paddings
      &-pt-lg-#{$i - 1} {
        padding-top: $size !important;
      }

      &-pb-lg-#{$i - 1} {
        padding-bottom: $size !important;
      }

      &-py-lg-#{$i - 1} {
        padding-top: $size !important;
        padding-bottom: $size !important;
      }

      // margins
      &-mt-lg-#{$i - 1} {
        margin-top: $size !important;
      }

      &-mb-lg-#{$i - 1} {
        margin-bottom: $size !important;
      }

      &-my-lg-#{$i - 1} {
        margin-top: $size !important;
        margin-bottom: $size !important;
      }
    }

  }

}

.spacer-group {

  @each $size in $sizes {

    $i: index($sizes, $size);

    // margins y
    &-my-#{$i - 1} {
      .spacer {
        @extend .spacer-my-#{$i - 1};

        &:first-child {
          margin-top:0 !important;
        }
        &:last-child {
          margin-bottom:0 !important;
        }

      }
    }

    // md variant
    @include media-breakpoint-up(md) {
      &-my-md-#{$i - 1} {
        .spacer {
          @extend .spacer-my-md-#{$i - 1};

          &:first-child {
            margin-top:0 !important;
          }
          &:last-child {
            margin-bottom:0 !important;
          }

        }
      }
    }

    // lg variant
    @include media-breakpoint-up(lg) {
      &-my-lg-#{$i - 1} {
        .spacer {
          @extend .spacer-my-lg-#{$i - 1};

          &:first-child {
            margin-top:0 !important;
          }
          &:last-child {
            margin-bottom:0 !important;
          }

        }
      }
    }

  }

}
