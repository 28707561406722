.google-translate {
  .goog-te-gadget {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1em;

    > * {
      font-size: 1rem !important;
    }

    > div {
      margin-right: 1em;
    }

    > span {
      margin-left: 0.5em;
    }

    select.goog-te-combo {
      font-size: 1rem !important;
      font-family: $font-main;
      background: $input-bg;
      border: 1px solid $gray-400;
      padding: $input-padding-y $input-padding-x;
      border-radius: $input-border-radius;
      margin: 0;
    }
  }
}

body > .skiptranslate {
  display: none;
}
