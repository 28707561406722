.result-item {
  h4, .h4 {
    font-weight:normal;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  h5, .h5 {
    @include font-size(18px);
  }

}

@include media-breakpoint-down(md) {
  .result-item {
    h4, .h4 {
      @include font-size(18px);
    }
  }
}
