.swipe-menu {

  position:relative;
  overflow:hidden;

  .swipe-container {

    position:relative;
    z-index:0;

    padding-top:18px;
    padding-bottom:12px;

    .ps__rail-y {
      display:none;
    }

    .ps__rail-x {
      html.touch & {
        display:none;
      }
    }

    ul {
      display: flex;
      flex-wrap: nowrap;
      list-style: none;
      margin: 0;
      padding: 0 16px;

      li {
        display: inline-block;
        flex: 0 0 auto;
        margin: 0;
        padding: 0;
        position: relative;

        > span,
        a {
          color: $brand-color-10;
          font-weight: 600;
          font-size: 16px;
          border-radius: 20px;
          padding:4px 15px 6px 15px;
          text-decoration: none;
          display:inline-block;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          transition: all .2s;

          &.is-active {
            background: $brand-color-10;
            color: #fff;

            &:hover {
              background: darken($brand-color-10, 5%);
              text-decoration: none;
            }

          }

          &:hover {
            text-decoration: underline;
          }

        }

        &:first-child {
          a:not(.is-active) {
            padding-left:0;
          }
        }

        &:last-child {
          padding-right:16px;
        }


      }

    }

    &.is-grabbing {


      > span,
      a {
        pointer-events: none !important;

        &:hover {
          color: $brand-color-10;
          background: transparent;
        }

      }

    }


  }

  @include media-breakpoint-up(lg){

    .swipe-container {
      padding-top:32px;
      padding-bottom:32px;
    }

    &:after {
      background: linear-gradient(90deg, rgba($body-bg,0) 0%, rgba($body-bg,1) 100%);
      width:10px;
      position:absolute;
      right:0;
      top:0;
      height:100%;
      content:"";
      z-index:2;
      pointer-events: none;
    }

    .swipe-container {

      ul {

        padding:0;

        li {
          padding: 0 15px 0 0;

          > span,
          a {
            font-size:18px;
          }

        }
      }
    }

  }


}

