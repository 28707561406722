.section--brochure {
  .col-md-5 {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .btn-link {
    padding-left: 0;
    padding-right: 0;
    color: #114DC9;
    text-decoration: underline;
  }
  .p-5 {
    p {
      font-weight: 300;
      line-height: 1.75;
      strong {
        font-weight: bold;
      }
    }
  }
}
