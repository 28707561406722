//$line-height-base: 1.7;

$font-main:                     $font-main;
$body-color:                    $brand-color-1;
$headings-color:                $brand-color-10;
$font-header:                   $font-header;
$body-bg:                       $brand-color-4;
$card-border-radius:            0;
$link-color:                    $brand-color-3;
$btn-border-radius:             4px;
$btn-border-radius-sm:          4px;
$btn-border-radius-lg:          4px;
$btn-padding-x:                 1.5em;
$btn-padding-y:                 0.5em;
$btn-font-size:                 1.125em;
$primary:                       $brand-color-6;
$input-height:                  50px;
$form-group-margin-bottom:      0.555555555555556em;
$card-border-color:             #d1dae4;
$border-color:                  #d1dae4;
$input-border-color:            #d1dae4;

$custom-select-font-size:       1em;
$custom-select-border-radius:   0;
$custom-select-height:          50px;
$custom-select-border-color:    transparent;


// Radio and Checkbox

$checked-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='z' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='09.01-DVU-contact' transform='translate(-189.000000, -893.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group' transform='translate(-42.000000, 520.000000)'%3E%3Cg id='Group-3-Copy' transform='translate(226.000000, 281.000000)'%3E%3Cg id='Group-2' transform='translate(0.000000, 39.000000)'%3E%3Cpath d='M17.3721077,53.3243988 L18.8466423,54.6756012 L11.0316423,63.2039215 C10.6721159,63.5962639 10.0753174,63.6340696 9.67049257,63.3099871 L9.58067466,63.2287715 L5.39567466,58.9646113 L6.82307534,57.563709 L10.269,61.075 L17.3721077,53.3243988 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

$custom-control-indicator-checked-bg: $brand-color-3;
$custom-control-indicator-size:         1.6rem;

$custom-checkbox-indicator-icon-checked: $checked-image;
$custom-radio-indicator-icon-checked: $checked-image;

$input-border-width:            1px;
$input-border-radius:           0;

$grid-gutter-width:             16px;
$input-focus-border-color:      $brand-color-3;
$input-focus-box-shadow:        0 0 0 4px rgba($brand-color-3, 0.25);
$form-grid-gutter-width:        16px;
$tooltip-bg:                    $brand-color-1;
$container-max-widths: (
        sm: 640px,
        md: 720px,
        lg: 960px,
        xl: 1140px
);

$grid-breakpoints: (
        xxs: 0,
        xs: 370px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
            0: 0,
            1: ($spacer * .25),
            2: ($spacer * .5),
            3: $spacer,
            4: ($spacer * 1.5),
            5: ($spacer * 3),
            6: ($spacer * 4.5),
            7: ($spacer * 6),
            32: 32px,
            64: 64px,
    ),
    $spacers
);
