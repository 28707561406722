.modal-backdrop {
  // Higher than Cookiebot 😅
  z-index: 10000;
}

.modal {

  font-size:16px;
  line-height: em(28,16);
  // Higher than backdrop 😅
  z-index: 10001;

  .form-control {
    &:focus {
      outline: 2px solid transparentize($brand-color-3, 0.75) !important;
      border-color: $brand-color-3;
      z-index: 10;
    }
  }

  .modal-header {
    border:none;
    padding:15px 15px 0 15px;
    font-size:17px;
    a {
      line-height:1;
    }
  }

  .modal-body {
    padding:40px 0;
  }

  .modal-footer {
    border:none;
    margin:0;
    padding:0 5px 5px 5px;
    .helper {
      margin:0;
    }
  }


  a {
    color: $brand-color-8;
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }

  }

  h3, .h3 {
    font-family: $font-serif;
    font-size: 34px;
    font-weight:normal;
    line-height: em(40, 34);
    margin-bottom:25px;
  }

  h4, .h4 {
    font-size: 22px;
    font-weight:normal;
    line-height: em(28, 22);
    margin-bottom:25px;
    font-weight:600;
  }

  .alert-wrong-postalcode {
    font-size:16px;
    line-height: em(24, 14);
    color: #dc3545;
    margin: 1.5rem 0;
  }

  .municipalities-list {
    width: 100%;
    columns: 20em auto;
    padding-left: 0;
    margin-bottom: 1.2em;

    &__item,
    &__link {
      display: flex;
    }

    &__item {
      break-inside: avoid;
      list-style-type: none;
      padding-left: 32px;
      line-height:1.2;
      padding: 0.5em 0;
    }

    &__link {
      color: $brand-color-8;
      display: flex;
      text-decoration: none;
      position: relative;
      padding-left: 32px;

      &:focus,
      &:hover {
        text-decoration: underline;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 14px;
        background-image: url('/src/images/icons/link-arrow-light.svg');
        background-repeat:no-repeat;
        background-size:100%;
        background-position: left center;
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }

  .helper {
    color: #5D6773;
    background: #F2F2F2;
    padding:15px;
    font-size:14px;
    line-height: em(24, 14);

    text-align: center;

    p {
      &:last-child {
        margin-bottom:0;
      }
    }

  }

  // tab switch
  .tab-switch {

    overflow: hidden;

    .switch-area {
      display:flex;
      flex-flow: row;
      width: 200%;

      @media (prefers-reduced-motion: no-preference) {
        transition: transform 700ms ease;
      }

      .tab {
        width: 100%;
        padding: 0 40px;

        .tab-content {
          display: none;
        }

        &.is-active {
          .tab-content {
            display: block;
          }
        }

        .postalcode-selector {
          margin: 30px auto 0;
          margin-left:-20px;
          margin-right:-20px;
        }

        @include media-breakpoint-only(xxs){
          padding: 0 20px;

          .postalcode-selector {
            margin-left: 0;
            margin-right: 0;
          }
        }

        @include media-breakpoint-up(lg){
          &[data-tab="postalcode"] {
            padding:0 80px;
          }

          .postalcode-selector {
            margin-left:auto;
            margin-right:auto;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg){

    .modal-header {
      padding:20px 20px 0 20px;
    }

    .postalcode-selector {
      margin-left:auto;
      margin-right:auto;
      max-width:360px;
    }

    .modal-footer {
      padding:0 30px 17px 30px;

      .helper {
        line-height:24px;
        padding-left:90px;
        padding-right:90px;
      }

    }

  }

}

