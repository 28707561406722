.demo-grid {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: none;
  z-index: 10;
  margin: 0 auto;
  max-width: 1140px;
  .row {
    margin-bottom: 4px;
    .col {
      height: 64px;
      &:before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(red, 0.5);
      }
    }
  }
  user-select: none;
}

body.show-demo-grid {
  .demo-grid {
    display: block;
  }
  .demo-block {
    box-shadow: 0px 0px 4px 4px #00ff00;
    margin-top: 0 !important;
    margin-bottom: 4em;
  }
  .section {
    box-shadow: 0px 0px 10px 0px red;
  }
}

main.content p.exitable:first-child {
  margin-top: 0;
}

p.component-description, p.exitable {
  margin: 1em 0;
  background-color: $brand-color-1;
  color: $brand-color-2;
  font-family: $font-header;
  font-weight: bold;
  display: block;
  clear: both;
  padding: 1em 0;
  text-align: center;
}

p.todo {
  @media (prefers-reduced-motion: no-preference) {
    animation-name: todo;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

.inline-grid {
  .row {
    margin-bottom: 4px;
    .col {
      height: 64px;
      &:before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background-color: $brand-color-3;
      }
    }
  }
  div.row:nth-child(6n+0) {
    margin-bottom: 0;
  }
}


ul.color-prev {
  li {
    margin-bottom: .25em;
    span {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      border-radius: 100px;
      overflow: hidden;
      vertical-align: bottom;
      margin-left: 0.5em;
    }
    &.color-1 {
      span {
        background-color: $brand-color-1;
      }
    }
    &.color-2 {
      span {
        background-color: $brand-color-2;
      }
    }
    &.color-3 {
      span {
        background-color: $brand-color-3;
      }
    }
  }
}
