$cb-position: 'leftBottom' !default;
$cb-max-width: 560px !default;

$cb-padding: 2em 3em 2.8em 3em !default;
$cb-border-radius: 4px !default;
$cb-font-family: Soleil !default;
$cb-font-size: 13px !default;
$cb-font-weight: normal !default;
$cb-line-height: 1.5 !default;

$cb-background-color: #D8E3F0 !default;
$cb-text-color: #04172D !default;

$cb-link-color: $cb-text-color !default;
$cb-link-decoration: underline !default;

$cb-link-color--hover: $cb-link-color !default;
$cb-link-decoration--hover: none !default;

// header
$cb-header-font-family: $cb-font-family !default;
$cb-header-font-size: 24px !default;
$cb-header-font-weight: bold !default;
$cb-header-text-color: #0A366A !default;
$cb-header-line-height: 1.5 !default;

// buttons
$cb-button-font-size: 14px !default;
$cb-button-font-weight: 600 !default;
$cb-button-line-height: $cb-line-height !default;
$cb-button-padding: 10px 17px !default;
$cb-button-radius: 4px !default;

// buttons - primary
$cb-button-primary-text-color: #fff !default;
$cb-button-primary-background-color: #0A366A !default;
$cb-button-primary-border-color: $cb-button-primary-background-color !default;
$cb-button-primary-text-decoration: none !default;

$cb-button-primary-text-color--hover: #fff !default;
$cb-button-primary-background-color--hover: #072447 !default;
$cb-button-primary-border-color--hover: $cb-button-primary-background-color--hover !default;
$cb-button-primary-text-decoration--hover: $cb-button-primary-text-decoration !default;

// buttons - light
$cb-button-light-text-color: #0A366A !default;
$cb-button-light-background-color: #D8E3F0 !default;
$cb-button-light-border-color: #0A366A !default;
$cb-button-light-text-decoration: none !default;

$cb-button-light-text-color--hover: $cb-button-light-text-color !default;
$cb-button-light-background-color--hover: darken($cb-button-light-background-color, 5%) !default;
$cb-button-light-border-color--hover: $cb-button-light-text-color !default;
$cb-button-light-text-decoration--hover: $cb-button-light-text-decoration !default;

// accordion
$cb-accordion-border-radius: 4px !default;
$cb-accordion-border-color: #0A366A !default;

$cb-accordion-header-padding: 10px 6px 10px 20px;
$cb-accordion-header-text-color: #0A366A !default;
$cb-accordion-header-font-size: $cb-button-font-size !default;
$cb-accordion-header-font-weight: 600 !default;

$cb-accordion-body-padding: 0 6px 15px 20px;

$cb-toggle: #0052CC;
$cb-toggle-inactive: #ccc !default;
$cb-toggle-active: $cb-toggle !default;
$cb-toggle-disabled: rgba($cb-toggle-active, .5) !default;

#cookiebanner {
  display: none;
  position: fixed;
  z-index: 9999;
  background-color: $cb-background-color;
  color: $cb-text-color;
  font-family: $cb-font-family;
  font-size: $cb-font-size;
  font-weight: $cb-font-weight;
  line-height: $cb-line-height;
  border-radius: $cb-border-radius;
  padding: $cb-padding;
  width: 100%;
  max-width: $cb-max-width;

  max-height: calc(100vh - 40px);
  overflow: hidden;
  overflow-y: auto;

  touch-action: auto;

  .cb-wrapper {
    display: flex;
    flex-direction: column;
  }


  @if ($cb-position == "leftBottom") {
    left: 20px;
    bottom: 20px;
  } @else if ($cb-position == "rightBottom") {
    right: 20px;
    bottom: 20px;
  } @else if ($cb-position == "leftTop") {
    left: 20px;
    top: 20px;
  } @else if ($cb-position == "rightTop") {
    right: 20px;
    top: 20px;
  }

  a {
    transition: all .2s;
  }

  button.cb-link,
  a.cb-link {
    color: $cb-link-color;
    text-decoration: $cb-link-decoration;
    display: inline-block;

    &:hover {
      color: $cb-link-color--hover;
      text-decoration: $cb-link-decoration--hover;
    }

  }

  button.cb-btn,
  a.cb-btn {
    font-size: $cb-button-font-size;
    font-weight: $cb-button-font-weight;
    padding: $cb-button-padding;
    border-radius: $cb-button-radius;
    line-height: $cb-button-line-height;
    box-sizing: content-box;
    cursor: pointer;
    margin-right: 1em;

    color: $cb-button-primary-text-color;
    background-color: $cb-button-primary-background-color;
    border: 1px solid $cb-button-primary-border-color;
    text-decoration: $cb-button-primary-text-decoration;

    &:last-child {
      margin-bottom: 0;
    }

    &--wide {
      padding-left: 35px;
      padding-right: 35px;
    }

    &:hover {
      color: $cb-button-primary-text-color--hover;
      background-color: $cb-button-primary-background-color--hover;
      border-color: $cb-button-primary-border-color--hover;
      text-decoration: $cb-button-primary-text-decoration--hover;
    }

    // light
    &--light {
      color: $cb-button-light-text-color;
      background-color: $cb-button-light-background-color;
      border: 1px solid $cb-button-light-border-color;
      text-decoration: $cb-button-light-text-decoration;

      &:hover {
        color: $cb-button-light-text-color--hover;
        background-color: $cb-button-light-background-color--hover;
        border-color: $cb-button-light-border-color--hover;
        text-decoration: $cb-button-light-text-decoration--hover;
      }
    }

    &.cb-btn--details {
      &.is-collapse {
        display:none;
      }
    }

  }

  // intro
  .cb-intro-header {
    font-family: $cb-header-font-family;
    font-size: $cb-header-font-size;
    font-weight: $cb-header-font-weight;
    color: $cb-header-text-color;
    line-height: $cb-header-line-height;
    margin-bottom: .5em;
  }

  .cb-intro-body {
    .cb-desc {
      margin-bottom: 1.2em;
    }
  }

  .cb-intro-footer {
    margin-top: calc(2em - 10px);
    display: flex;
    flex-flow: row wrap;

    .cb-btn {
      margin-top: 10px;
    }

  }

  // details

  .cb-details {
    display: none;

    .cb-details-body {
      margin-top: 2em;
    }

    .cb-details-footer {
      margin-top: 3em;
    }

    &.is-collapse {
      display: block;
    }

  }

  .cb-accordion-item {

    border-radius: $cb-accordion-border-radius;
    border: 1px solid $cb-accordion-border-color;
    margin-bottom: 6px;

    .cb-accordion-header {
      position: relative;
      padding: $cb-accordion-header-padding;
      color: $cb-accordion-header-text-color;
      font-size: $cb-accordion-header-font-size;
      font-weight: $cb-accordion-header-font-weight;
      display: flex;
      flex-flow: row;
      align-items: center;
      line-height: 26px;

      .cb-accordion-title {
        flex: 1 1 0px;
        padding-left: 20px;
        cursor: pointer;
        position: relative;
        display: block;

        &:before {
          content: "";
          width: 14px;
          height: 14px;
          display: inline-block;
          background-image: escape-svg(url('data:image/svg+xml,<svg width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="#{$cb-header-text-color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.88" d="m1.761 12.278 5.5-5.5-5.5-5.5"/></svg>'));
          background-repeat: no-repeat;
          background-position: center;
          background-size: 9px;
          position: absolute;

          left: 0;
          top: 50%;
          margin-top: -8px;
        }

      }

      .cb-accordion-toggle {
        flex-shrink: 1;
        flex: 0 0 auto;
        padding-left: 1em;
      }

      a {
        color: currentColor !important;
        text-decoration: none !important;
        font-weight: inherit !important;
      }

    }

    .cb-accordion-body {
      padding: $cb-accordion-body-padding;
      padding-top: 0;
    }

    .cb-accordion-collapse {
      display: none;
      overflow: hidden;
    }

    &.is-collapse {
      .cb-accordion-header {
        .cb-accordion-title {
          &:before {
            transform: rotate(90deg);
          }
        }
      }
      .cb-accordion-collapse {
        display: block;
      }
    }

  }

  .cb-switch {
    display: inline-flex;
    flex-flow: row;
    font-weight: normal;
    align-items: center;
    margin-bottom: 0;
  }

  .switch-toggle-container {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
    margin-left: 1em;

    .switch-toggle {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $cb-toggle-inactive;
      transition: .4s;

      &:before {
        position: absolute;
        content: "";
        height: 24px;
        width: 24px;
        left: 1px;
        bottom: 1px;
        background-color: white;
        transition: .4s;
      }

      // round variant
      &--round {
        border-radius: 26px;

        &:before {
          border-radius: 50%;
        }

      }

    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked + .switch-toggle {
      background-color: $cb-toggle-active;
    }

    input:focus + .switch-toggle {
      box-shadow: 0 0 1px $cb-toggle-inactive;
    }

    input:checked + .switch-toggle:before {
      transform: translateX(26px);
    }

    input:focus:checked + .switch-toggle {
      box-shadow: 0 0 1px $cb-toggle-active;
    }

    input:disabled + .switch-toggle {
      background-color: $cb-toggle-disabled !important;
      cursor: default;
    }

  }

  // mobile screens
  @media screen and (max-width:767px) {
    max-width: 100%;
    width: unset;
    right: 20px;
    left: 20px;
    padding: 1rem 1rem 1.5rem 1rem;

    .cb-intro-header {
      line-height: 1.2;
    }

    .cb-btn {
      display: block;
      text-align: center;
      margin-bottom:.5rem;
    }

    .cb-intro-footer {
      .cb-btn {
        width: 100%;
        margin-right: 0;
      }
    }

    .cb-details-footer {
      .cb-btn {
        margin-right: 0;
      }
    }

    .switch-label {
        display: none;
    }

  }

  //@media screen and (max-width:440px) {
  //  .cb-intro-body {
  //    .cb-desc {
  //      display: none;
  //    }
  //  }
  //}

}

.cb-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
