.postalcode-selector {

  #postalcode-input {
    opacity:0;
  }

  .pincode-input-container {

    display:flex;
    flex-flow:row;

    .form-control {
      border:2px solid #EBEFF3;
      border-radius: 10px;
      font-size:47px;
      box-shadow:none !important;
      text-align:center;
      text-transform: uppercase;
      font-weight:500;
      color: $brand-color-1;
      height:80px;
      width:55px !important;
      width:calc((100% / 6)) !important;
      padding:0;
      margin-left:-2px;

      @media screen and (max-width: 320px) {
        font-size:30px;
      }

      &.is-wrong {
        color: #dc3545;
      }

      &:nth-child(6){
        margin-left:4%;
      }

      &.first {
        margin-left: 0;
      }

      @include placeholder() {
        color: #C2CFDC;
      }
    }

  }

  .btn {
    margin:25px 0 0 0;
  }

}
