.pagination {

  display: flex;
  margin:0;
  padding:0;

  li {
    list-style:none;
    margin:0 5px;
    padding:0;
  }

  .page-item {
    width:40px;
    height:40px;
    position:relative;

    .page-link {
      height:100%;
      width:100%;
      position: absolute;
      left:0;
      top:0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .2s;
      text-decoration: none;
      font-size:16px;
      color: $body-color;;
      background: $white;
      font-weight:normal;
      line-height: 1;
      cursor: pointer;
      border:none;
      svg {
        height:17px;
        width: 17px;
        fill: $brand-color-6;
      }

    }



    &--prev {
      .page-link {
        &:before {
          content: '';
          display: inline-block;
          width: 18px;
          height: 14px;
          background-image: url('/src/images/icons/arrow-left.svg');
          background-repeat:no-repeat;
          background-size:100%;
          background-position: left center;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }

    &--next {
      .page-link {
        &:before {
          content: '';
          display: inline-block;
          width: 18px;
          height: 14px;
          background-image: url('/src/images/icons/arrow-right.svg');
          background-repeat:no-repeat;
          background-size:100%;
          background-position: left center;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }

    &.is-active {

      .page-link {
        color: $white;
        background: $brand-color-6;
        cursor: default;
      }

    }

    &.is-disabled {
      .page-link {
        opacity:0.2 !important;
        pointer-events: none;
      }
    }

    a.page-link {
      &:hover {
        color: $brand-color-6;
        box-shadow: 0px 0px 10px 0 rgba($brand-color-1, 0.2)
      }

    }
  }

}
