// Header

#sticky-header {
  position: sticky;
  top: 0;
  z-index: 9;
  transition: all .5s cubic-bezier(.29,.26,0,.99);

  &.is-scrollup {
    transform: translateY(-100%);
  }

}

#header {
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1);
  position: relative;

  a {
    text-decoration: none;
  }

  .container {
    @include media-breakpoint-down(md) {
      max-width: none;
      padding: 0;
    }
  }

  #hamburger {
    background-color: white;
    width:60px;
    height:60px;
    text-align: center;
    display: inline-block;
    position: relative;
    margin-left: 0;
    //&:before {
    //  content: '';
    //  display: block;
    //  background-color: inherit;
    //  @include stick-to-edges();
    //  transform: translateX(8px) skewX(-16deg);
    //
    //  display:none;
    //
    //}
    img {
      vertical-align: top;
      margin: 20px 0 19px;
      width: 28px;
      height: 21px;
      position: relative;
    }
  }
  #logo {
    display: inline-flex;
    width: 68px;
    height: 60px;
    align-items: center;
    margin-left: 1em;
    @include media-breakpoint-up(lg) {
      width: auto;
      height: 90px;
      margin-left:0;
    }

    img {
      vertical-align: top;
      width: 62px;
      height: auto;
      margin:0;
      @include media-breakpoint-up(lg) {
        width: 93px;
        height: 54px;
        margin: 0;
      }
    }

    &.logo.logo--anniversary {
      width: 159px;
      height: 60px;

      img {
        width: 95px;
        height: 36px;

        @include media-breakpoint-up(lg) {
          width: 143px;
          height: 54px;
        }
      }
    }

    span.branche {
      color: #0A366A;
      font-size: 12px;
      font-weight: bold;
      @include media-breakpoint-up(lg) {
        font-size: 14px;
      }
    }
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  #support {
    display: inline-block;
    text-align: center;
    margin-right:5px;
    span.icon, span.title {
      display: block;
    }
    span.icon{
      margin-bottom: 2px;
      img {
        width: 21px;
        height: 21px;
      }
    }
    span.title {
      font-size: 12px;
      line-height: 1;
      color: #0A366A;
    }
  }
}

.dropdown-menu--custom {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  .wrapper {
    background: white;
  }
}

.mobile-footer-menu {

  .town-selector {
    padding:33px 20px;
    background: $brand-color-1;
    color: #FFF;
    display:block;
    text-decoration: none;

    .title {
      font-size:24px;
      line-height:1;
      color: #FFF;
      margin-bottom:15px;
    }

    .desc {
      font-size:18px;
      color: $brand-color-9;
      display:flex;
      align-items: center;
      justify-content: space-between;

      .link-edit {
        color: #FFF;
        font-size:16px;
        text-decoration: underline;
      }

    }

  }

  .footer-menu-item {
    font-size:24px;
    color: $brand-color-1;
    background: $brand-color-4;
    font-weight:500;
    text-decoration: none;
    display:flex;
    align-items: center;
    padding:33px 20px;
    line-height:1;
    transition: all .2s;
    &:focus,
    &:hover {
      background: darken($brand-color-4, 4.5%);
    }

    .icon {
      margin-right:15px;
      line-height:1;

      img {
        width:28px;
        height:31px;
      }

    }

  }
}

.language-selector {
  position: relative;

  [aria-hidden=true] {
    display: none;
  }

  &__dropdown {
    position: absolute;
    right: 0;
    background: $white;
    padding: map-get($spacers, 3);
    width: 35em;
    z-index: 100;
    color: $black;
    border: 1px solid $brand-color-1;
    margin-top: 6px;
  }
}
