// Paragraph types

.media-left-text-right {

  &:not(.custom-order){
    .media {
      @include media-breakpoint-up(md) {
        order: 1;
      }
    }
    .text {
      @include media-breakpoint-up(md) {
        order: 2;
      }
    }
  }

  .text {
    @include media-breakpoint-down(md) {
      margin-top: 1.5rem;
    }
  }
}

.media-right-text-left {

  &:not(.custom-order) {
    .media {
      order: 2;
    }
    .text {
      order: 1;
    }
  }

  .text {
    @include media-breakpoint-down(md) {
      margin-bottom: 1.5rem;
    }
  }

}
