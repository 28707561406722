// News
#news-slider.slick-initialized {
  &:after {
    //content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('/src/images/news/shadow.png');
    background-size: cover;
    width: 25vw;
    pointer-events: none;
    opacity: 0.25;
  }
  .slick-slide {
    > div {
      height: 100%;
      > .col-4 {
        height: 100%;
      }
    }
  }
}
