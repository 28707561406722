// IE

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  #header {
    position: relative;
  }
  .section--hero {
    .media {
      &:after {
        content: none;
        display: none;
      }
    }
  }
  .section--hero:not(.section--hero-pull) {
    > .container {
      > .row {
        min-height: 0 !important;
        height: auto;
        .caption {
          margin-top: 12vh;
          margin-bottom: 12vh;
        }
      }
    }
  }
  .section--hero-pull {
    > .container {
      > .row {
        min-height: 0 !important;
      }
    }
  }
  .gallery-thumbs .swiper-slide {
    margin-right: 10px;
  }
}
