// Footer

#footer {
  .h3 {
    font-weight: bold;
    color: #8BACD2;
    font-family: $font-main;
    font-size: 1.25em;
    margin-bottom: 1rem;
  }
  a {
    color: inherit;
    text-decoration: none;
  }

  .link-edit {
    text-decoration: underline;
    color: $brand-color-8;
    &:focus,
    &:hover {
      text-decoration: none;
      color: $brand-color-8;
    }
  }

  .menu--footer {
    margin-bottom: 2em;
    li {
      margin-bottom: 0.8em;
      a {
        &:hover, &:focus, active {
          color: $brand-color-2;
        }
      }
    }
  }
  .top {
    background: $brand-color-1;
    padding: 3em 0;
    color: white;
    ul.contact-info {
      margin-bottom: 2em;
      @include media-breakpoint-up(lg) {
        font-size: 20px;
      }
      li {
        a {
          &:hover, &:focus {
            color: $brand-color-2;
          }
        }
      }
      li:first-child {
        font-weight: normal;
      }
    }
    .emblem-container {
      //overflow: hidden;
    }

    .emblem-ratio {
      max-width: 100px;

      img.emblem {
        width: 100%;
        max-width:unset;
        @media (prefers-reduced-motion: no-preference) {
          animation-name: rotateEmblem;
          animation-duration: 60000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }

    }


    @keyframes rotateEmblem {
      from { transform: rotate3d(0, 0, 1, 0deg); }
      to { transform: rotate3d(0, 0, 1, 360deg); }
    }
  }
  .middle {
    background-image: url('images/footer/footer-bg.jpg');
    background-size: 100% 100%;
    padding: 8px 0;
    text-transform: lowercase;
    min-height: 32px;
  }
  .bottom {
    background-color: white;
    color: $brand-color-1;
    padding: 1em 0;
    @include media-breakpoint-up(lg) {
      padding: 2em 0;
    }
    .menu--privacy {
      margin: 0;
      li {
        display: inline-block;
        margin: 0;
        a {
          display: block;
          margin: 0.25em 1em 0.25em 0;
          &:hover, &:focus, .active {
            color: inherit;
          }
        }
      }
    }
    p {
      margin: 0.25em 0;
    }
  }
}


html, body, #container {
  height: 100%;
}

#container {
  display: flex;
  flex-direction: column;
}

#wrapper {
  flex: 1 0 auto;
}

#footer {
  flex-shrink: 0;
}

.footer-logos {
  .h3 {
    font-weight: normal !important;
    font-size: 18px !important;
  }

  hr {
    background-color: $brand-color-5;
    margin: 0.75rem 0 1.5rem;
  }

  ul {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;

    @include media-breakpoint-up(sm) {
      display: flex;
      gap: 2rem;
    }
  }

  li {
    text-align: center;

    img {
      max-height: 60px;
    }

    &.footer-logo--kringloop {
      img {
        max-height: 40px;
      }
    }

    &.footer-logo--geofonds {
      img {
        max-height: 12px;
      }
    }
  }
}
