$accordion-transition:                     color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
$accordion-icon-transition:               transform .2s ease-in-out;

.accordion {
  border-top:1px solid $brand-border-color;
  border-bottom:1px solid $brand-border-color;
  margin-top:15px;
}

.accordion-item {
  border-bottom:1px solid $brand-border-color;
  &:last-child {
    border-bottom:0;
  }

}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  background:transparent;
  width: 100%;
  padding:20px;
  padding-left:30px;
  text-align: left; // Reset button style
  border: 0;
  overflow-anchor: none;
  @include transition($accordion-transition);
  color:$brand-color-1;
  font-weight: 600;
  @include font-size(20px);


  &:not(.collapsed) {

    color:$brand-color-1;

    &::after {
      transform: rotate(90deg);
    }

    .more-or-less {
      .less {
        display:block;
      }
      .more {
        display:none;
      }
    }

  }

  // Accordion icon
  &::after {
    position:absolute;
    left:9px;

    top:24px;
    flex-shrink: 0;
    width:10px;
    height:16px;
    margin-left: auto;
    content: "";
    background-image: escape-svg(url("data:image/svg+xml,<svg viewBox='0 0 10 16' xmlns='http://www.w3.org/2000/svg' fill='#{$brand-color-1}'><path d='M.833 15.167a1.65 1.65 0 01-.107-2.216l.107-.118L5.667 8 .833 3.167A1.65 1.65 0 01.726.95L.833.833A1.65 1.65 0 013.05.726l.118.107 6 6a1.65 1.65 0 01.107 2.216l-.107.118-6 6a1.65 1.65 0 01-2.334 0z'/></svg>")) !important;
    background-repeat: no-repeat;
    background-size: 100%;
    @include transition($accordion-icon-transition);
  }

  &:hover {
    z-index: 2;
    //background: darken($brand-color-4, 5%);
  }

  &:focus {
    z-index: 3;
    outline: 0;
  }

  &.collapsed {

    &:hover {

      &:after {
        background-image: escape-svg(url("data:image/svg+xml,<svg viewBox='0 0 10 16' xmlns='http://www.w3.org/2000/svg' fill='#{$brand-color-1}'><path d='M.833 15.167a1.65 1.65 0 01-.107-2.216l.107-.118L5.667 8 .833 3.167A1.65 1.65 0 01.726.95L.833.833A1.65 1.65 0 013.05.726l.118.107 6 6a1.65 1.65 0 01.107 2.216l-.107.118-6 6a1.65 1.65 0 01-2.334 0z'/></svg>")) !important;
      }

    }

  }


}

.accordion-header {
  margin-bottom: 0;
}

.accordion-body {

  padding:0 20px 20px 0;

  b, strong {
    color:$brand-color-1;
  }

  a {
    color:$brand-color-1;
    text-decoration: underline;

    &:hover {
      color: $brand-color-3;
    }
  }

}
