// Hero

.section--hero {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 0;
  background: linear-gradient(45deg, #002657 0%, #019c90 100%);

  .section--back-link {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 11;
  }

  p {
    color: $white;
    font-size: 18px;

    a {
      color: currentColor;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .label {
    font-size: 18px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    background: $brand-color-1;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
    }
  }

  .media {
    width: 330px;
    height: 330px;
    margin-top: -70px;
    margin-right: -40px;
    margin-bottom: -48px;
    margin-left: auto;
    position: relative;

    .img-container {
      width: 100%;
      height: 100%;
      position: relative;

      .img {
        position: absolute;
        width: 100%;
        overflow: hidden;
        z-index: 1;

        border-radius: 50%;
        padding-bottom: 100%;
        height: 0;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      @keyframes heroRotate {
        100% {
          transform: rotate(1turn);
        }
      }

      .anim {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 125.5%;
        padding-bottom: 125.5%;
        height: 0;
        z-index: 2;

        &:after {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          content: "";
          display: block;
          background-image: url("/src/images/hero/circular-gradient.png");
          background-size: cover;

          @media (prefers-reduced-motion: no-preference) {
            animation: heroRotate 30000ms linear infinite;
          }
        }
      }
    }
  }
  .caption {
    margin: 0;
    color: white;
    position: relative;
    z-index: 10;
    $height: 48px;
    padding-top: $height;
    padding-bottom: $height;

    .pre-title {
      font-size: 1em;
      margin: 0;
      color: $brand-color-2;
      font-weight: bold;
      text-transform: lowercase;

      @include media-breakpoint-up(lg) {
        font-size: 1.75em;
      }
    }

    .title {
      font-size: 2.125em;
      margin-bottom: 0;
      color: inherit;
      font-weight: normal;
      font-family: $font-serif;

      span {
        color: $brand-color-2;
      }
    }
  }

  &.section--hero-no-image {
    .media {
      margin-bottom: -158px;
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    &.section--hero-fullwidth-mobile {
      .media {
        width: 100%;
        max-width: 100%;
        position: relative;
        margin: 0;
        height: 330px;
        left: 0;
        display: block;

        .img-container {
          max-width: 100%;

          .img {
            border-radius: 0;
            padding-bottom: 0;
            height: 100%;
          }

          .anim {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background: linear-gradient(45deg, #002657 0%, #019c90 75%, #91c957 100%);

    &.section--hero-no-image {
      .caption .container .row {
        min-height: 100px;
      }
    }

    $height: 70px;

    .caption {
      padding-top: $height;
      padding-bottom: $height;
    }

    .caption .container .row {
      min-height: 228px;
    }

    .media {
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 46%;
      width: auto;
      height: auto;
      max-width: 844px;
    }

    .caption {
      position: relative;

      .pre-title {
        font-size: 1.25em;
      }

      .title {
        font-size: 2.75em;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .caption {
      .pre-title {
        font-size: 1.75em;

      }
      .title {
        font-size: 3.25em;
      }
    }
  }
}

.section--hero-home {
  @include media-breakpoint-down(md) {
    .media {
      display: none;
    }
  }
}

.section--hero-pull {
  $height: 48px;
  .caption {
    padding-bottom: ($height * 2);
  }
  & + #main {
    > .section:first-child {
      padding-top: 0;
    }
    .pull-item {
      margin-top: -$height;
    }
    .push-item {
      margin-top: $height;
    }
  }
  @include media-breakpoint-up(md) {
    $height: 70px;
    .caption {
      padding-bottom: ($height * 2);
    }
    & + #main {
      .pull-item {
        margin-top: -$height;
      }
      .push-item {
        margin-top: $height;
      }
    }
  }
}


.section--hero-text-only {
  padding-top: 2em;

  .hero-caption {
    .pre-title {
      font-size: 1em;
      margin: 0;
      color: $brand-color-5;
      font-weight: bold;
      text-transform: lowercase;

      @include media-breakpoint-up(md) {
        font-size: 1.25em;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.75em;
      }
    }

    .title {
      font-size: 2.125em;
      font-weight: normal;
      margin-bottom: 0;
      font-family: $font-serif;

      @include media-breakpoint-up(md) {
        font-size: 2.75em;
      }

      @include media-breakpoint-up(lg) {
        font-size: 3.25em;
      }
    }
  }
}
