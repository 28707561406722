.filter-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.filters {

  display: flex;
  flex-flow: row wrap;

  .btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;

    + .btn {

      font-size:16px;
      padding: 0.4rem 1rem 0.6rem 1rem;
      border-radius: 22px;

      color: $brand-color-6;
      border-color: $brand-color-6;
      white-space: nowrap;

    }

    &:checked,
    &:not(.btn-check)+.btn:active {
      + .btn {
        color: #fff;
        background-color: $brand-color-6;
        border-color: $brand-color-6;
      }
    }

  }

  @include media-breakpoint-down(sm){
    .btn {
      min-width: 40%;
    }
  }

}
