// Mixins
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin stick-to-edges {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin transition($transition) {
  -o-transition:      $transition;
  -ms-transition:     $transition;
  -moz-transition:    $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
