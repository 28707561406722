.form-autocomplete {
  position: relative;
  &.autocomplete-is-active {
    .input-group {
      border-bottom-right-radius: 0 !important;
    }
  }

  .input-group:focus-within {
    outline: 4px solid transparentize($white, 0.5) !important;
  }
}

.autocomplete-suggestions {
  background: #fff;
  color: $gray-700;
  padding: 20px 30px;
  box-shadow: 0 40px 40px 0 rgb(0 0 0 / 15%);

  a {
    text-decoration: none
  }

  h3 {
    @include font-size(20px);
    margin-bottom:10px;
    color: $brand-color-1;
  }

  .btn-link {
    color: $brand-color-1;
    &:focus,
    &:hover {
      text-decoration: underline;
      color: $brand-color-3;
    }
  }

  .autocomplete-suggestion {
    display: block;
    padding:8px;
    color: $brand-color-3;
    border-radius: 2px;
    @include font-size(18px);
    margin-left: -8px;
    margin-right: -8px;

    &.autocomplete-selected {
      background-color: $brand-color-11;
      color: $brand-color-6;
      cursor: pointer !important;
    }
  }

  .autocomplete-footer {
    //border-top: 1px solid $brand-border-color;
    margin: 0 -30px -20px;
    padding: 15px 30px 20px;
    @include font-size(18px);
  }

  @include media-breakpoint-down(lg){
    padding: 10px 10px;

    h3 {
      @include font-size(18px);
    }

    .btn-link,
    .autocomplete-suggestion {
      @include font-size(16px);
    }

    .autocomplete-footer {
      margin: 0 -15px -10px;
      padding: 7px 15px 10px;
    }
  }

}

.autocomplete-suggestion {
  cursor: pointer;
}
