// Base settings
// ===========================
$transition-duration:     300ms;

// Custom Fonts
// ===========================
@import url("https://use.typekit.net/etq5usy.css");

// Font settings
// ===========================
$font-size:               1rem;
$font-main:               'Soleil', Helvetica, Arial, sans-serif;
$font-header:             'Soleil', Helvetica, Arial, sans-serif;
$font-serif:              'bree-serif', serif;
$link-decoration: underline;

// General colors
// ===========================
$brand-color-1:           #04172D;
$brand-color-2:           #F9F91D;
$brand-color-3:           #114dc9;
$brand-color-4:           #EBEFF3;
$brand-color-5:           #456FA0;
$brand-color-6:           #0A366A;
$brand-color-7:           #a1a1a1;
$brand-color-8:           #236BFF;
$brand-color-9:           #8BACD2;
$brand-color-10:          #0A366A;
$brand-color-11:          #C2CFDC;

$brand-black-1: #131313;

// Borders
// ===========================
$brand-border-color:            rgba($brand-color-1, .2);

// Social colors
// ===========================
$facebook-color:          #3b5998;
$instagram-color:         #e95950;

// Shadows
// ===========================
$box-shadow-1:            0px 0px 8px 0px rgba(0,0,0,0.25);
$box-shadow-2:            2px 2px 8px 0px rgba(0,0,0,1);
$box-shadow-3:            0px 0px 24px 0px rgba(0,0,0,0.12);
$text-shadow-1:           2px 2px 4px #000000;

// Shadows
// ===========================
$section-margin-bottom:   4vh;

// Slick
// ===========================
$slick-loader-path: "/node_modules/slick-carousel/slick/";
$slick-font-family: false;

