.waste-seperation-blocks {

}

.waste-seperation-block {
    padding: map-get($spacers, 4);

    &--allowed {
        background: #65b32e;
    }


    &--forbidden {
        background: #cd1719;
    }

    + .waste-seperation-block {
        margin-top: map-get($spacers, 4);
    }
}

.waste-seperation-block__title {
    color: $white !important;
    margin-bottom: map-get($spacers, 4);
}

.waste-seperation-block__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-gap: map-get($spacers, 3);
    justify-content: start;

    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(2, minmax(1fr, 1fr));
    }
}

.waste-seperation-block__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    background: $white;
    padding: map-get($spacers, 2);
    color: $black;

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
}

.waste-yes-no-link {
    color: $brand-color-1;
    text-decoration-color: transparent;

    &:hover {
        color: $brand-color-3;
        text-decoration-color: transparent;
    }
}

.waste-yes-no-item.has-link {
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.05);
        transition: transform 0.3s;
    }
}

