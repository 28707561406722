// Skiplinks

#skiplinks {
  background-color: black;
  color: white;
  ul.menu {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 14px;
    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
    li {
      list-style-type: none;
      a {
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        padding: 1em;
        display:  block;
        color: $brand-color-2;
        &:focus {
          position: static;
          transform: none;
          text-decoration: underline;
        }
      }
    }
  }
  a.my {
    background-color: $brand-color-3;
  }
}
