.submenu {
  a {
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  li.is-active {
    color :$brand-color-3;
  }
}
