// Section
//
//.section:not(.spacers) {
//  padding: 32px 0;
//}

.section--hero {
  padding: 0;
}

.section--back-link {
  padding-top:18px;
  .section--hero & {
    padding-top:32px;
  }

  @include media-breakpoint-up(lg) {
    padding-top:32px;
  }
}

.section--white {
  background-color: white;
}

.section--dark-gray {
  //background-color: $brand-color-11;
  background-color:  rgba($brand-color-1, .05);


}

.section--light-gray {
  background-color: $brand-color-4;
}

.section--featured {
  padding: 4em 0;
}

.section--salestool {
}

.section--breadcrumb {
  padding: 10px 0;
}

.section--categories {
  //
}

.section--swipe-menu {
  padding:0;

  .container-lg {
    position: relative;
    @include media-breakpoint-up(lg){
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        //left: 50%;
        left: 0;
        height: 1px;
        //margin-left: -50vw;
        //width: 100vw;
        width: 100%;
        border-bottom:1px solid $brand-border-color;
      }
    }
  }


}

.section--products {
  padding-top: 64px;
}

.section--product {
  > .container > .row {
    .col-lg-5:nth-child(2n) {
      @include media-breakpoint-up(lg) {
        padding-left: 48px;
      }
      h1.section-title {
        margin-bottom: 1rem;
      }

    }
  }
}

.section--login {
  @include media-breakpoint-up(lg) {
    padding: 16vh 0;
  }
}

//#main {
//  > .section:not(.spacers):first-child {
//    padding-top: 32px;
//  }
//
//  >.section:not(.spacers):last-child {
//    padding-bottom: 32px;
//  }
//}


@include media-breakpoint-up(lg) {
  #main {
    > .section:first-child {
      //padding-top: 64px;
      > .container > .card--clean:first-child > .card-body {
        padding-top: 0;
      }
    }

    > .section:last-child {
      //padding-bottom: 64px;

      > .container > .card--clean:last-child > .card-body {
        padding-bottom: 0;
      }
    }

  }

}
