.input-group {

  &--search,
  &--faq {

    background: $white;
    border-radius: 0 35px 35px 0;
    box-shadow: 0 0 40px 0 rgba(0,0,0,0.15);
    align-items: center;

    /* Disable autofill highlighting */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      //border: 1px solid green;
      //-webkit-text-fill-color: green;
      //-webkit-box-shadow: 0 0 0px 1000px #000 inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    > .form-control {
      background: transparent;
      border-color: transparent;
      box-shadow: none !important; // hover and foces

      padding: 20px 30px;
      font-size:18px;
      height:70px;

      font-weight: $font-weight-normal;

      @include placeholder() {
        color: $brand-color-7;
      }

      &:focus {
        color: $black;
      }

    }

    > .btn {
      box-shadow: none !important; // hover and foces
      border-color: transparent;

      height:52px;
      width:52px;
      border-radius: 50% !important;
      padding:0;
      margin: 0;
      background: $brand-color-6;
      color: $white;
      margin-right:10px;
      display:flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        opacity: .2;
      }

      span {
        display:inline-block;
        height: 27px;
        width: 27px;
        line-height:0;
      }

      svg {
        height:100%;
        width:100%;
        margin-top:-2px;

        path {
          fill: $white !important;
        }
      }

    }

  }

  @include media-breakpoint-down(lg){
    max-width: 100%;

    > .form-control {
      padding-left:10px;
      font-size:17px;
    }

  }

}
